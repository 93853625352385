import { PaletteOptions, createTheme } from '@mui/material/styles';
import { indigo, grey, red } from '@mui/material/colors';
const grey1000 = "#111111";
export const lightPalette: PaletteOptions = {
  mode: "light",
  error: {
    main: red[700]
  },
  info: {
    main: indigo[700]
  },
  primary: {
    main: indigo[700],
    contrastText: indigo[50]
  },
  background: {
    default: grey[300],
    paper: "white"
  },
  text: {
    primary: grey1000,
    secondary: grey[500]
  },
  divider: "#d1d1d1"
};
export const light = createTheme({
  palette: lightPalette,
  components: {
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          color: indigo[900],
          border: "1px solid " + indigo[700]
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: indigo[50]
          }
        }
      }
    }
  }
});
export default light;
import axios from "axios";
const tag = "@api/editObserver";
const url = "api/editObserver";
export const editObserver = async (observer, subscription, municipalityID): Promise<void> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        uuidObserver: observer,
        endPoint: subscription.endpoint,
        keys: subscription,
        municipalityID,
        active: true
      }
    };
    let res = await axios(aopts);
    if (res.status === 200) {
      return;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default editObserver;
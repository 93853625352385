import { blue, green, yellow, grey, brown } from '@mui/material/colors';
export const containerListItem = {
  "Papírové a lepenkové obaly": {
    color: "#2c4295",
    background: blue[100]
  },
  "Plastové obaly": {
    color: "#dcd831",
    background: yellow[100]
  },
  "Skleněné obaly": {
    color: green[400],
    background: green[100]
  },
  "Papír a lepenka": {
    color: "#2c4295",
    background: blue[100]
  },
  "Sklo": {
    color: green[400],
    background: green[100]
  },
  "Biologicky rozložitelný odpad z kuchyní a stravoven": {
    color: "#896a16",
    background: brown[100]
  },
  "Plasty": {
    color: "#dcd831",
    background: yellow[100]
  },
  "Biologicky rozložitelný odpad": {
    color: "#896a16",
    background: brown[100]
  },
  "Směsný komunální odpad": {
    color: "#5b5b5b",
    background: grey[400]
  }
};
export default containerListItem;
import { themePalette } from "@theme";
import { CircularProgress, Paper } from "@mui/material";
import { desktopWidth } from "@lib/desktop";
import { getContainerReportTypes, createContainerReport, getHouseholds, getContainerInstances } from "@api";
import { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import BackButton from "@common/BackButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import containerListItem from "@common/ContainerListItem";
import Content from "@common/Content";
import DeleteIcon from "@mui/icons-material/Delete";
import i18next from "i18next";
import IconButton from "@mui/material/IconButton";
import MenuButton from "@common/MenuButton";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import ProgressContext from "@component/core/Context/Progress/ProgressContext";
import React from "react";
import SelectContext from "@component/core/Context/Select/SelectContext";
import SendIcon from "@mui/icons-material/Send";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import View from "@common/View";
import { indigo } from "@mui/material/colors";
import updateHouseholdSettings, { updateHouseholdSettingsEmitter } from "@lib/util/updateHouseholdSettings";
const tag = "@component/view/Report";
const defaultContainerListItem = containerListItem["Směsný komunální odpad"];
function ContainerInstanceListItem({
  item,
  colorPalette,
  settings,
  selected,
  onClick
}) {
  const {
    pushNavigation
  } = useContext(NavigationContext);
  const containerListItemColor = containerListItem[item.wasteTypeCatalogName] || defaultContainerListItem;
  return <Button sx={{
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
    borderBottom: "1px solid " + colorPalette.divider,
    backgroundColor: selected ? indigo[50] : colorPalette.background.paper
  }} onClick={onClick}>
            {/* Left */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "0 0 auto",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      paddingRight: "5px"
    }}>
                <DeleteIcon sx={{
        fontSize: "48px",
        color: containerListItemColor.color
      }} />
            </Box>

            {/* Center */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      justifyContent: "center",
      alignItems: "stretch",
      padding: "10px",
      paddingLeft: "5px"
    }}>
                <Typography variant="body1" component="div" sx={{
        textAlign: "left",
        fontWeight: "bold",
        color: colorPalette.text.primary
      }}>
                    {item.text}
                </Typography>
                <Typography variant="caption" component="div" sx={{
        textAlign: "left",
        color: colorPalette.text.primary
      }}>
                    <b>{i18next.t("view.Household.containerInstanceID")}:</b> {item.id}
                </Typography>
            </Box>
        </Button>;
}
function Header({
  navItem,
  sendDisabled,
  send,
  sending
}) {
  return <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    {!navItem.data && <MenuButton />}
                    {!!navItem.data && <BackButton />}
                    <Typography variant="h6" component="div" sx={{
          flexGrow: 1
        }}>
                        {i18next.t("view.Report.headerTitle")}
                    </Typography>
                    <IconButton size="large" color="inherit" disabled={sendDisabled} onClick={send}>
                        {!sending && <SendIcon />}
                        {!!sending && <CircularProgress size={20} color="inherit" variant="indeterminate" />}
                    </IconButton>
                </Toolbar>
            </AppBar>
        </React.Fragment>;
}
export function Report({
  navItem
}) {
  const [selected, setSelected] = useState({
    id: null,
    name: null
  });
  const [sending, setSending] = useState(false);
  const [description, setDescription] = useState("");
  const {
    settings
  } = useContext(SettingsContext);
  const [household, setHousehold] = useState(navItem.data ? {
    id: navItem.data.householdID,
    name: navItem.data.householdName
  } : {
    id: settings.householdID,
    name: settings.householdName
  });
  const [containerInstance, setContainerInstance] = useState(navItem.data ? {
    id: navItem.data.containerInstanceID,
    name: navItem.data.containerInstanceName
  } : {
    id: null,
    name: null
  });
  const {
    setSelect
  } = useContext(SelectContext);
  const {
    setProgress
  } = useContext(ProgressContext);
  const {
    backNavigation
  } = useContext(NavigationContext);
  const colorPalette = themePalette(settings.theme);
  async function send() {
    if (sending) {
      return;
    }
    setSending(true);
    try {
      await createContainerReport(settings.municipalityID, `${description}` + (navItem.data && navItem.data.dt ? `\n\n(${i18next.t("view.Report.pickupDt")}: ${navItem.data.dt})` : ""), selected.id, typeof household.id === "number" ? household.id : null, typeof containerInstance.id === "number" ? containerInstance.id : null);
      setDescription("");
      setHousehold({
        id: settings.householdID,
        name: settings.householdName
      });
      setContainerInstance({
        id: null,
        name: null
      });
      setSending(false);
      setProgress({
        open: true,
        text: i18next.t("view.Report.sendSuccess"),
        isSnack: true,
        alert: "success"
      });
      if (navItem.data) {
        backNavigation();
      }
    } catch (e) {
      setSending(false);
      setProgress({
        open: true,
        text: i18next.t("view.Report.sendError"),
        isSnack: true,
        alert: "error"
      });
    }
  }
  async function onContainerReportTypeClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getContainerReportTypes()).map(item => ({
        ...item,
        key: item.id,
        text: item.name
      })),
      loadingText: i18next.t("view.Report.loadContainerReportTypes.loading"),
      loadErrorText: i18next.t("view.Report.loadContainerReportTypes.error"),
      noDataText: i18next.t("view.Report.loadContainerReportTypes.noData"),
      selected,
      onSelect: async item => {
        setSelected({
          id: item.id,
          name: item.name
        });
      }
    });
  }
  async function onContainerInstanceClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getContainerInstances(household.id)).map(item => ({
        ...item,
        key: item.id,
        text: `${item.text}`
      })),
      renderItem: (item, selected, onClick) => {
        return <ContainerInstanceListItem item={item} selected={selected} onClick={onClick} colorPalette={colorPalette} settings={settings} />;
      },
      searchID: true,
      loadingText: i18next.t("view.Report.loadContainerInstances.loading"),
      loadErrorText: i18next.t("view.Report.loadContainerInstances.error"),
      noDataText: i18next.t("view.Report.loadContainerInstances.noData"),
      selected: containerInstance.id,
      onSelect: async item => {
        setContainerInstance({
          id: item.key,
          name: `${item.text} (ID: ${item.key})`
        });
      }
    });
  }
  async function onHouseholdClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getHouseholds(settings.municipalityID)).map(item => ({
        ...item,
        key: item.id,
        text: item.name
      })),
      loadingText: i18next.t("view.Report.loadHouseholds.loading"),
      loadErrorText: i18next.t("view.Report.loadHouseholds.error"),
      noDataText: i18next.t("view.Report.loadHouseholds.noData"),
      selected: household.id,
      onSelect: async item => {
        setHousehold({
          id: item.key,
          name: item.name
        });
      }
    });
  }
  function onTextChange(ev) {
    setDescription(ev.target.value);
  }
  useEffect(() => {
    updateHouseholdSettings();
  }, [navItem]);
  return <View navItem={navItem}>
            <Header navItem={navItem} sendDisabled={typeof selected.id !== "number" || !description} send={send} sending={sending} />
            <Content>
                <Paper sx={{
        padding: 2,
        maxWidth: desktopWidth
      }}>
                    <Box sx={{
          flex: "1 1 auto",
          flexDirection: "column",
          display: "flex"
        }}>
                        {/* Report type */}
                        <Typography sx={{
            fontWeight: "bold",
            paddingBottom: 1
          }}>
                            {`${i18next.t("view.Report.reportType")} *`}
                        </Typography>
                        <Button sx={{
            marginBottom: "14px",
            padding: 1.5
          }} variant="contained" onClick={onContainerReportTypeClick}>
                            {selected.name ? selected.name : i18next.t("common.unfilled")}
                        </Button>

                        {/* Household */}
                        <Typography sx={{
            fontWeight: "bold",
            paddingBottom: 1
          }}>
                            {i18next.t("view.Report.household")}
                        </Typography>
                        <Button sx={{
            marginBottom: "14px",
            padding: 1.5
          }} variant="contained" onClick={onHouseholdClick}>
                            {household.name ? household.name : i18next.t("common.unfilled")}
                        </Button>

                        {/* Container instance */}
                        <Typography sx={{
            fontWeight: "bold",
            paddingBottom: 1
          }}>
                            {i18next.t("view.Report.containerInstance")}
                        </Typography>
                        <Button sx={{
            marginBottom: "14px",
            padding: 1.5
          }} variant="contained" disabled={typeof household.id !== "number"} onClick={onContainerInstanceClick}>
                            {containerInstance.name ? containerInstance.name : i18next.t("common.unfilled")}
                        </Button>

                        {/* Description */}
                        <Typography sx={{
            fontWeight: "bold",
            paddingBottom: 1
          }}>
                            {`${i18next.t("view.Report.description")} *`}
                        </Typography>
                        <TextField sx={{
            display: 'flex',
            flex: "1 1 auto"
          }} InputProps={{
            sx: {
              height: '100%',
              alignItems: 'start',
              flex: "1 1 auto"
            }
          }} onChange={onTextChange} label="" value={description} placeholder={i18next.t("view.Report.descriptionPlaceholder")} multiline minRows={4} />
                    </Box>
                </Paper>
            </Content>
        </View>;
}
export default Report;
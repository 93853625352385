import { createContext } from "react";
import { SettingsContextState } from "@component/core/Context/Settings/typeDefs";
import db from "@lib/db";
export const initState: SettingsContextState = {
  id: undefined,
  dbInitialized: false,
  theme: undefined,
  municipalityID: null,
  municipalityName: null,
  householdID: null,
  householdName: null,
  uuidObserver: null,
  useReport: false,
  showEventTime: true,
  pwaInstalled: false,
  hideInstallPrompt: true
};
export const SettingsContext = createContext({
  settings: initState,
  setSettings: (diff: Partial<SettingsContextState>) => {},
  mergeSettings: (diff: Partial<SettingsContextState>) => {}
});
export default SettingsContext;
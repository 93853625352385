import { NavigationContextStateHistoryItemKey } from "@component/core/Context/Navigation/typeDefs";
import { PwaContext, initState } from "@component/core/Context/Pwa/PwaContext";
import { PwaContextState } from "@component/core/Context/Pwa/typeDefs";
import { useState, useEffect, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import db from "@lib/db";
import merge from "@lib/merge";
import notificationsInfo from "@lib/notifications/info";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import config from "@config";
import { getHouseholdSettings } from "@api";
import updateHouseholdSettings, { updateHouseholdSettingsEmitter } from "@lib/util/updateHouseholdSettings";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import { info as windowInfo } from "@lib/window";
const tag = "@component/core/Context/Pwa/PwaContextProvider";
const info = {
  dt: Date.now()
};
export function PwaContextProvider({
  children
}) {
  const [state, setState] = useState(initState);
  const stateRef = useRef(state);
  const setStateRef = useRef(setState);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  useEffect(() => {
    setStateRef.current = setState;
  }, [setState]);
  useEffect(() => {
    windowInfo.emitter.on("prompt", prompt => {
      const s: PwaContextState = merge(stateRef.current, ({
        prompt
      } as any));
      setStateRef.current(s);
    });
    const run = async () => {
      const dbSettings = await db.Settings.get();
      const s = {
        showInstall: !dbSettings.pwaInstalled,
        showIOSInstallInfo: !dbSettings.pwaInstalled,
        prompt: stateRef.current.prompt || windowInfo.prompt
      };
      setStateRef.current(s);
    };
    run();
  }, []);
  const data = {
    pwa: state,
    setPwa: async (diff: Partial<PwaContextState>) => {
      const s = merge(initState, diff);
      windowInfo.prompt = s.prompt;
      setState(s);
    },
    mergePwa: async (diff: Partial<PwaContextState>) => {
      const s = merge(state, diff);
      windowInfo.prompt = s.prompt;
      setState(s);
    }
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.pwa = data;
  }
  return <PwaContext.Provider value={data}>
            {children}
        </PwaContext.Provider>;
}
export default PwaContextProvider;
import dexie from "@db/dexie";
import dbUtil from "@db/util";
import { Table, TableInstanceSet } from "@db/table/typeDefs";
import { Settings as SettingsSchema } from "@db/table/Settings/typeDefs";
const tableName = Table.SETTINGS;
const get = () => {
  return dbUtil.get(tableName, (rec: SettingsSchema) => rec.id === 1);
};
const set = data => {
  return dbUtil.set(tableName, data);
};
const init = async () => {
  const cnt = await dexie[tableName].count();
  if (cnt === 0) {
    return dbUtil.set(tableName, {
      dbInitialized: false,
      theme: "light",
      municipalityID: null,
      municipalityName: null,
      householdID: null,
      householdName: null,
      uuidObserver: null,
      useReport: false,
      showEventTime: true,
      pwaInstalled: false,
      hideInstallPrompt: false
    });
  }
};
export const Settings: TableInstanceSet<SettingsSchema> = {
  dexie: dexie[tableName],
  get,
  set,
  init
};
export default Settings;
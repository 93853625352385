import { createContext } from "react";
import { ProgressContextState } from "@component/core/Context/Progress/typeDefs";
export const initState: ProgressContextState = {
  open: false,
  text: null,
  percentage: null,
  isSnack: false,
  alert: null
};
export const ProgressContext = createContext({
  progress: initState,
  setProgress: (diff: Partial<ProgressContextState>) => {}
});
export default ProgressContext;
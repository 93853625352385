import { createObserver, editObserver, getPublicVapidKey } from "@api";
import serviceWorkerInfo from "@lib/serviceWorker/info";
import info from "@lib/notifications/info";
import db from "@lib/db";
import removeObserver from "@api/removeObserver";
const tag = "@lib/notifications";
export const sendNotificationCommand = async () => {
  if (process.env.NODE_ENV === 'development') {
    if (info.subscription) {}
  }
};
export const unsubscribe = async () => {
  if (serviceWorkerInfo.registration === null) {
    LOG(tag, "unsubscribe: no service worker registration, ending ...");
    return;
  }
  let subscription = null;
  try {
    subscription = await serviceWorkerInfo.registration.pushManager.getSubscription();
  } catch (e) {
    LOG(tag, "unsubscribe: error detected while getting subscription from service worker, error: %O, ending ...", e);
    return;
  }
  let observer = await getObserver();
  if (observer) {
    try {
      await removeObserver(observer);
      await db.Settings.set({
        uuidObserver: null
      });
      info.observer = null;
      info.emitter.emit("observer", info);
      info.emitter.emit("change", info);
    } catch (e) {
      LOG(tag, "unsubscribe: error detected while removing observer, error: %O", e);
    }
  }
  info.subscription = null;
  info.emitter.emit("subscription-change", info);
  info.emitter.emit("change", info);
};
const getObserver = async () => {
  let s = null;
  try {
    s = await db.Settings.get();
  } catch (e) {
    return null;
  }
  return s.uuidObserver || null;
};
export const registerObserver = async () => {
  if (!info.subscription) {
    LOG(tag, "registerObserver: no current subscription, not registering observer");
    return;
  }
  let observer = await getObserver();
  if (!observer) {
    observer = null;
    try {
      const s = await db.Settings.get();
      observer = await createObserver(info.subscription, s.municipalityID);
      await db.Settings.set({
        uuidObserver: observer
      });
      info.observer = observer;
      info.emitter.emit("observer", info);
      info.emitter.emit("change", info);
    } catch (e) {
      LOG(tag, "registerObserver: error detected while creating observer, error: %O", e);
      observer = null;
      info.observer = null;
      info.emitter.emit("observer", info);
      info.emitter.emit("change", info);
    }
  } else {
    info.observer = observer;
    info.emitter.emit("observer", info);
    info.emitter.emit("change", info);
    try {
      const s = await db.Settings.get();
      await editObserver(observer, info.subscription, s.municipalityID);
    } catch (e) {
      LOG(tag, "registerObserver: error detected while editing observer, error: %O", e);
    }
  }
};
export const subscribe = async (force: boolean = false) => {
  if (serviceWorkerInfo.registration === null) {
    LOG(tag, "subscribe: no service worker registration, ending ...");
    return;
  }
  if (info.permission !== "granted") {
    LOG(tag, "subscribe: notification permission is not granted, ending ...");
    return;
  }
  const applicationServerKey = await getPublicVapidKey();
  let subscription = null;
  try {
    subscription = await serviceWorkerInfo.registration.pushManager.getSubscription();
  } catch (e) {
    LOG(tag, "subscribe: error detected while getting current subscription, error: %O", e);
    subscription = null;
  }
  info.subscription = JSON.parse(JSON.stringify(subscription));
  info.emitter.emit("subscription-change", info);
  info.emitter.emit("change", info);
  if (subscription === null || force) {
    subscription = JSON.parse(JSON.stringify(await serviceWorkerInfo.registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey
    })));
    info.subscription = subscription;
    info.emitter.emit("subscription-change", info);
    info.emitter.emit("change", info);
  }
  sendNotificationCommand();
  registerObserver();
};
export const requestNotificationPermission = async (): Promise<NotificationPermission> => {
  let result = null;
  try {
    result = await window.Notification.requestPermission();
    if (result === "granted") {
      await onPermissionChange(result);
    }
  } catch (e) {
    LOG(tag, "requestNotificationPermission: error detected while requestiong notification permission, error: %O, ending ...", e);
    return "default";
  }
  return result;
};
const onNotificationMessage = async ev => {};
const listenNotificationMessages = () => {
  if (!navigator || !navigator.serviceWorker) {
    return;
  }
  navigator.serviceWorker.addEventListener("message", event => {
    const action = event.data.action;
    if (action === "notification") {
      onNotificationMessage(event);
    }
  });
};
const onPermissionChange = async (perm: NotificationPermission) => {
  if (perm !== info.permission) {
    info.permission = perm;
    info.emitter.emit("permission-change", info);
    info.emitter.emit("change", info);
  }
  const notificationsEnabled = info.permission === "granted";
  if (notificationsEnabled) {
    await subscribe();
  } else {
    await unsubscribe();
  }
};
const listenPermissionChanges = async () => {
  if (!navigator || !("permissions" in navigator)) {
    LOG(tag, "listenPermissionChange: navigator.permissions not supported, ending ...");
    return;
  }
  let notificationPerm = null;
  try {
    notificationPerm = await navigator.permissions.query({
      name: "notifications"
    });
  } catch (e) {
    LOG(tag, "listenPermissionChange: error detected while quering navigator.permissions (notifications), error: %O, ending ...", e);
    return;
  }
  if (!notificationPerm) {
    LOG(tag, "listenPermissionChange: couldnt get notification permission from navigator.permissions, ending ...");
    return;
  }
  notificationPerm.onchange = async function () {
    await onPermissionChange(this.state !== "prompt" ? this.state : "default");
  };
};
const init = async () => {
  if ("Notification" in window) {
    await onPermissionChange(window.Notification.permission);
  } else {
    LOG(tag, "init: Notification is not supported, ending ...");
    return;
  }
  listenPermissionChanges();
  listenNotificationMessages();
  info.ready = true;
  info.emitter.emit("ready", info);
  info.emitter.emit("change", info);
};
export default {
  init,
  info,
  requestNotificationPermission,
  unsubscribe,
  subscribe
};
import notifications from "@lib/notifications";
import info from "@lib/serviceWorker/info";
const tag = "@lib/serviceWorker";
export const registerServiceWorker = async () => {
  if (!("serviceWorker" in navigator)) {
    LOG(tag, "registerServiceWorker: service worker registration failed (!navigator.serviceWorker)");
    return null;
  }
  let registration = null;
  try {
    registration = await navigator.serviceWorker.register("/service-worker.js");
    info.registration = registration;
    info.emitter.emit("change", info);
    info.emitter.emit("registration", info);
  } catch (e) {
    LOG(tag, "registerServiceWorker: service worker registration failed, error: %O", e);
  }
  return registration;
};
export const init = async () => {
  const registration = await registerServiceWorker();
  if (!registration) {
    LOG(tag, "init: service worker not registered, ending ...");
    return;
  }
  info.ready = true;
  info.emitter.emit("change", info);
  info.emitter.emit("ready", info);
  notifications.init();
};
export default {
  registerServiceWorker,
  init
};
import { blue, green, yellow, grey, brown, red, indigo } from "@mui/material/colors";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { getNotifications, getPickupCalendarEvents } from "@api";
import { NavigationContextStateHistoryItemKey } from "@core/Context/Navigation/typeDefs";
import { themePalette } from "@theme";
import { Virtuoso } from "react-virtuoso";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import containerListItem from "@common/ContainerListItem";
import Content from "@common/Content";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import i18next from "i18next";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import MenuButton from "@common/MenuButton";
import moment from "moment-timezone";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import React, { useContext, useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import View from "@common/View";
import updateHouseholdSettings from "@lib/util/updateHouseholdSettings";
import isIOS from "@lib/util/isIOS";
import PwaContext from "@component/core/Context/Pwa/PwaContext";

//const localizer = momentLocalizer(moment);
const tag = "@component/view/CalendarEvents";
const defaultContainerListItem = containerListItem["Směsný komunální odpad"];
export function CalendarEventListItem({
  item,
  colorPalette,
  hasNotifications,
  currentDay,
  mergePwa,
  ios,
  standalone
}) {
  const {
    pushNavigation
  } = useContext(NavigationContext);
  const {
    settings
  } = useContext(SettingsContext);
  const dt = moment(item.dtStarted).format("D. M. YYYY");
  const timeStart = settings.showEventTime ? moment(item.dtStarted).format("HH:mm") : "";
  const timeEnd = settings.showEventTime ? moment(item.dtEnded).format("HH:mm") : "";
  const containerListItemColor = containerListItem[item.wasteTypeCatalogName] || defaultContainerListItem;
  function edit() {
    if (!!ios && !standalone) {
      mergePwa({
        showInstall: false,
        showIOSInstallInfo: true
      });
    } else {
      pushNavigation({
        key: NavigationContextStateHistoryItemKey.SET_NOTIFICATION,
        data: {
          item,
          containerListItemColor
        }
      });
    }
  }
  return <Box sx={{
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
    borderBottom: "1px solid " + colorPalette.divider,
    backgroundColor: currentDay ? indigo[50] : "00000000"
  }}>
            {/* Left */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "0 0 auto",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      paddingRight: "5px"
    }}>
                {!item.systemEvent && <DeleteIcon sx={{
        fontSize: "48px",
        color: containerListItemColor.color
      }} />}
                {!!item.systemEvent && <InfoIcon sx={{
        fontSize: "48px",
        color: colorPalette.text.primary
      }} />}
            </Box>

            {/* Center */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      justifyContent: "center",
      alignItems: "stretch",
      padding: "10px",
      paddingLeft: "5px"
    }}>
                {/* Non-system event */
      !item.systemEvent && <React.Fragment>
                            <Typography variant="body1" component="div" sx={{
          textAlign: "left",
          fontWeight: "bold"
        }}>
                                {item.wasteTypeCatalogName}
                            </Typography>
                            {!!item.description && <Typography variant="caption" component="div" sx={{
          textAlign: "left"
        }}>
                                    <b>{i18next.t("view.CalendarEvents.description")}: </b>{item.description ? item.description : ""}
                                </Typography>}
                            <Typography variant="caption" component="div" sx={{
          textAlign: "left"
        }}>
                                <b>{i18next.t("view.CalendarEvents.dt")}: </b>{dt} {timeStart}{timeStart && timeEnd ? " - " : ""}{timeEnd}
                            </Typography>
                        </React.Fragment>}
                {/* System event */
      !!item.systemEvent && <React.Fragment>
                            <Typography variant="body1" component="div" sx={{
          textAlign: "left",
          fontWeight: "bold"
        }}>
                                {item.title ? item.title : item.text}
                            </Typography>
                            {!!item.text && !!item.title && <Typography variant="body2" component="div" sx={{
          textAlign: "left"
        }}>
                                    {item.text ? item.text : ""}
                                </Typography>}
                            <Typography variant="caption" component="div" sx={{
          textAlign: "left"
        }}>
                                <b>{i18next.t("view.CalendarEvents.dt")}: </b>{dt} {timeStart}
                            </Typography>
                        </React.Fragment>}
            </Box>

            {/* Right */
    !item.systemEvent && <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="info" onClick={edit}>
                        {!hasNotifications && <NotificationsOutlinedIcon />}
                        {!!hasNotifications && <NotificationsIcon />}
                    </IconButton>}
        </Box>;
}
function Header({
  mode,
  onModeChange
}) {
  return <AppBar position="static">
            <Toolbar>
                <MenuButton />
                <Typography variant="h6" component="div" sx={{
        flexGrow: 1
      }}>
                    {i18next.t("view.CalendarEvents.headerTitle")}
                </Typography>
                {/*
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={mode === "list"}
                            color="default"
                            onChange={onModeChange}/>}
                            label={i18next.t("view.CalendarEvents.modeList")}
                            color="inherit"
                        />
                    </FormGroup>
                 */}
            </Toolbar>
        </AppBar>;
}
function Loading({
  colorPalette
}) {
  return <Box sx={{
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Box sx={{
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: 90,
      height: 90
    }}>
                <CircularProgress size={60} variant="indeterminate" />
            </Box>
            <Typography variant="caption" component="div" color="text.secondary">
                {i18next.t("view.CalendarEvents.loadingCalendarEvents")}
            </Typography>
        </Box>;
}
function LoadError({
  colorPalette,
  error,
  refresh
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.error.main,
      textAlign: "center"
    }}>
                {error}
            </Typography>
            <Button variant="outlined" color="error" sx={{
      marginTop: 1,
      padding: 1.5
    }} startIcon={<RefreshIcon />} onClick={refresh}>
                {i18next.t("common.refresh")}
            </Button>
        </Box>;
}
function Empty({
  colorPalette
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center"
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.text.secondary,
      textAlign: "center"
    }}>
                {i18next.t("view.CalendarEvents.noData")}
            </Typography>
        </Box>;
}
const initItems = {
  items: [],
  notificationKey: {},
  loading: true,
  dt: Date.now(),
  error: null
};
export function CalendarEvents({
  navItem
}) {
  const [mode, setMode] = useState("list");
  const [items, setItems] = useState(initItems);
  const [dt, setDt] = useState({
    startDt: moment().startOf("day"),
    endDt: moment().add(1, "month")
  });
  const {
    settings
  } = useContext(SettingsContext);
  const colorPalette = themePalette(settings.theme);
  const {
    mergePwa
  } = useContext(PwaContext);
  const ios = isIOS();
  const standalone = useMediaQuery("(display-mode: standalone)");
  function renderListItem(index, item) {
    const m1 = moment(item.dtStarted).startOf("day");
    const m2 = moment().startOf("day");
    return <CalendarEventListItem item={item} hasNotifications={!!items.notificationKey[`${item.id};${item.dtStarted};${item.dtEnded}`]} currentDay={m1.diff(m2) === 0} colorPalette={colorPalette} mergePwa={mergePwa} ios={ios} standalone={standalone} />;
  }
  async function refresh() {
    try {
      setItems(initItems);
      const i = {
        items: await getPickupCalendarEvents(settings.municipalityID, dt.startDt.format("YYYY-MM-DDTHH:mm:ss.SSS"), dt.endDt.format("YYYY-MM-DDTHH:mm:ss.SSS")),
        notificationKey: settings.uuidObserver ? (await getNotifications({
          uuidObserver: settings.uuidObserver,
          forObserver: true
        })).notifications.map(n => `${n.eventID};${n.dtStarted};${n.dtEnded}`).reduce((acc, value) => {
          acc[value] = true;
          return acc;
        }, {}) : {},
        dt: Date.now(),
        loading: false,
        error: null
      };
      setItems(i);
    } catch (e) {
      LOG(tag, "refresh: error detected while gettings calendar events, error: %O", e);
      setItems({
        ...initItems,
        loading: false,
        dt: Date.now(),
        error: i18next.t("view.CalendarEvents.loadingCalendarEventsError")
      });
    }
  }
  useEffect(() => {
    updateHouseholdSettings();
    refresh();
  }, [navItem]);
  function onModeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMode(event.target.checked ? "list" : "calendar");
  }
  return <View navItem={navItem}>
            <Header mode={mode} onModeChange={onModeChange} />
            <Content style={{
      backgroundColor: colorPalette.background.paper,
      padding: 0
    }}>
                {/*mode === "calendar" && (
                    <Calendar
                        localizer={localizer}
                        events={[]}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                    />
                 )*/}
                {/*(
                    <DateCalendar
                        value={dt.startDt}
                        views={['month', 'year']}
                        openTo="month"
                    />
                 )*/}
                {/* Calendar events as list */
      mode === "list" && <React.Fragment>
                            {/* Loading items */
        items.items.length === 0 && items.loading && <Loading colorPalette={colorPalette} />}
                            {/* Cannot load items - error */
        items.items.length === 0 && !items.loading && !!items.error && <LoadError colorPalette={colorPalette} error={items.error} refresh={refresh} />}
                            {/* No data */
        items.items.length === 0 && !items.loading && !items.error && <Empty colorPalette={colorPalette} />}
                            {/* Item list */
        items.items.length > 0 && <Virtuoso style={{
          flex: "1 1 auto"
        }} context={{
          dt: items.dt,
          notificationKey: items.notificationKey
        }} data={items.items} totalCount={items.items.length} itemContent={renderListItem} />}
                        </React.Fragment>}
            </Content>
        </View>;
}
export default CalendarEvents;
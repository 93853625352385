import { useContext } from "react";
import Markdown from 'markdown-to-jsx';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@mui/material";
import PwaContext from "@component/core/Context/Pwa/PwaContext";
import i18next from "i18next";
import { themePalette } from "@theme";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import isIOS from "@lib/util/isIOS";
const tag = "@component/common/Pwa";
export function Pwa() {
  const {
    pwa,
    mergePwa
  } = useContext(PwaContext);
  const {
    settings,
    mergeSettings
  } = useContext(SettingsContext);
  const colorPalette = themePalette(settings.theme);
  const ios = isIOS();
  const standalone = useMediaQuery("(display-mode: standalone)");
  function close() {
    mergePwa({
      showInstall: false,
      showIOSInstallInfo: false
    });
    mergeSettings({
      hideInstallPrompt: true
    });
  }
  async function install() {
    if (!ios && !standalone && !!pwa.prompt && pwa.prompt.prompt) {
      pwa.prompt.prompt();
      const {
        outcome
      } = await pwa.prompt.userChoice;
      mergePwa({
        prompt: null
      });
      if (outcome === "accepted") {} else if (outcome === "dismissed") {}
    }
    close();
  }
  return <Dialog open={!standalone && (pwa.showInstall && !settings.hideInstallPrompt && pwa.prompt && pwa.prompt.prompt || ios && pwa.showIOSInstallInfo)} onClose={close}>
            <DialogTitle id="alert-dialog-title">
                {!ios ? i18next.t("Pwa.install.title") : i18next.t("Pwa.iosInstallWalkthrough.title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{
        color: colorPalette.text.primary
      }}>
                    <Markdown>
                        {!ios ? i18next.t("Pwa.install.text") : i18next.t("Pwa.iosInstallWalkthrough.text")}
                    </Markdown>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} autoFocus>
                    {!ios ? i18next.t("common.cancel") : i18next.t("common.ok")}
                </Button>
                {!ios && <Button onClick={install} autoFocus>
                        {i18next.t("Pwa.install.install")}
                    </Button>}
            </DialogActions>
        </Dialog>;
}
export default Pwa;
import getHouseholdSettings from "@api/getHouseholdSettings";
import db from "@db";
import config from "@config";
import EventEmitter from "eventemitter3";
const tag = "@lib/util/updateHouseholSettings";
export const info = {
  dt: null,
  intervalID: null
};
export const updateHouseholdSettingsEmitter = new EventEmitter();
const updateHouseholdSettings = async () => {
  const dt = Date.now();
  info.dt = dt;
  try {
    const s = await db.Settings.get();
    if (typeof s.municipalityID === "number" && s.municipalityID >= 0) {
      const householdSettings = await getHouseholdSettings(s.municipalityID);
      if (info.dt === dt) {
        const settings = {
          ...s,
          ...householdSettings
        };
        await db.Settings.set(settings);
        updateHouseholdSettingsEmitter.emit("update", settings);
      }
    }
  } catch (e) {
    LOG(tag, "error detected while updating household settings, error: %O", e);
  }
};
if (typeof config.householdSettings.refreshInterval === "number" && config.householdSettings.refreshInterval >= 0) {
  info.intervalID = setInterval(updateHouseholdSettings, config.householdSettings.refreshInterval);
}
updateHouseholdSettings();
export default updateHouseholdSettings;
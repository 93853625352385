import { Button, CircularProgress, IconButton, Paper } from "@mui/material";
import { containerListItem } from "@common/ContainerListItem";
import { NavigationContextStateHistoryItemKey } from "@component/core/Context/Navigation/typeDefs";
import { themePalette } from "@theme";
import { useContext, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import Alert from '@mui/material/Alert';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Content from "@common/Content";
import DeleteIcon from "@mui/icons-material/Delete";
import FeedbackIcon from "@mui/icons-material/Feedback";
import getPickups from "@api/getPickups";
import i18next from "i18next";
import MenuButton from "@common/MenuButton";
import moment from "moment-timezone";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import React from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import View from "@common/View";
const tag = "@component/view/Pickups";
const defaultContainerListItem = containerListItem["Směsný komunální odpad"];
export function PickupListItem({
  item,
  colorPalette,
  settings
}) {
  const containerListItemColor = containerListItem[item.wasteTypeCatalogName] || defaultContainerListItem;
  const {
    pushNavigation
  } = useContext(NavigationContext);
  const dt = moment(item.dtPickup).format("D. M. YYYY HH:mm");
  function reportPickup() {
    const opts = {
      key: NavigationContextStateHistoryItemKey.REPORT,
      data: {
        householdID: settings.householdID,
        householdName: settings.householdName,
        containerInstanceID: item.containerInstanceID,
        containerInstanceName: `${item.text} (ID: ${item.containerInstanceID})`,
        dt
      }
    };
    pushNavigation(opts);
  }
  return <Box sx={{
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
    borderBottom: "1px solid " + colorPalette.divider
  }}>
            {/* Left */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "0 0 auto",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      paddingRight: "5px"
    }}>
                <DeleteIcon sx={{
        fontSize: "48px",
        color: containerListItemColor.color
      }} />
            </Box>

            {/* Center */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      justifyContent: "center",
      alignItems: "stretch",
      padding: "10px",
      paddingLeft: "5px"
    }}>
                <Typography variant="body1" component="div" sx={{
        textAlign: "left",
        fontWeight: "bold"
      }}>
                    {item.text}
                </Typography>
                <Typography variant="caption" component="div" sx={{
        textAlign: "left"
      }}>
                    <b>{i18next.t("view.Pickups.containerInstanceID")}:</b> {item.containerInstanceID}
                </Typography>
                <Typography variant="caption" component="div" sx={{
        textAlign: "left"
      }}>
                    <b>{i18next.t("view.Pickups.pickupDt")}:</b> {dt}
                </Typography>
            </Box>

            {/* Right */}
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="info" onClick={reportPickup}>
                <FeedbackIcon />
            </IconButton>
        </Box>;
}
function Header() {
  return <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <MenuButton />
                    <Typography variant="h6" component="div" sx={{
          flexGrow: 1
        }}>
                        {i18next.t("view.Pickups.headerTitle")}
                    </Typography>
                </Toolbar>
            </AppBar>
        </React.Fragment>;
}
function Info({
  municipalityName,
  householdName,
  from,
  to
}) {
  return <Paper sx={{
    marginBottom: 1,
    flex: "0 0 auto"
  }}>
            <Alert severity="info" variant="outlined" sx={{
      padding: 1
    }}>
                <Box sx={{
        display: "flex",
        flexDirection: "column",
        flex: "0 0 auto",
        justifyContent: "center",
        alignItems: "stretch"
      }}>
                    <Typography variant="body2" component="div" sx={{
          textAlign: "left"
        }}>
                        <b>{i18next.t("view.Pickups.municipality")}:</b> {municipalityName}
                    </Typography>
                    <Typography variant="body2" component="div" sx={{
          textAlign: "left"
        }}>
                        <b>{i18next.t("view.Pickups.household")}:</b> {householdName}
                    </Typography>
                    {!!from && <Typography variant="body2" component="div" sx={{
          textAlign: "left"
        }}>
                            <b>{i18next.t("view.Pickups.from")}:</b> {from}
                        </Typography>}
                    {!!to && <Typography variant="body2" component="div" sx={{
          textAlign: "left"
        }}>
                            <b>{i18next.t("view.Pickups.to")}:</b> {to}
                        </Typography>}
                </Box>
            </Alert>
        </Paper>;
}
function Loading({
  colorPalette
}) {
  return <Box sx={{
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Box sx={{
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: 90,
      height: 90
    }}>
                <CircularProgress size={60} variant="indeterminate" />
            </Box>
            <Typography variant="caption" component="div" color="text.secondary">
                {i18next.t("view.Pickups.loadingPickups")}
            </Typography>
        </Box>;
}
function LoadError({
  colorPalette,
  error,
  refresh
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.error.main,
      textAlign: "center"
    }}>
                {error}
            </Typography>
            <Button variant="outlined" color="error" sx={{
      marginTop: 1,
      padding: 1.5
    }} startIcon={<RefreshIcon />} onClick={refresh}>
                {i18next.t("common.refresh")}
            </Button>
        </Box>;
}
function Empty({
  colorPalette
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center"
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.text.secondary,
      textAlign: "center"
    }}>
                {i18next.t("view.Pickups.noData")}
            </Typography>
        </Box>;
}
const initItems = {
  items: [],
  loading: true,
  dt: Date.now(),
  error: null,
  from: "",
  to: ""
};
export function Pickups({
  navItem
}) {
  const [items, setItems] = useState(initItems);
  const {
    settings
  } = useContext(SettingsContext);
  const colorPalette = themePalette(settings.theme);
  function renderListItem(index, item) {
    return <PickupListItem item={item} colorPalette={colorPalette} settings={settings} />;
  }
  async function refresh() {
    try {
      setItems(initItems);
      const from = moment().subtract(12, "months");
      const to = moment();
      const i = {
        items: await getPickups(settings.householdID, from.toISOString()),
        from: from.format("D. M. YYYY HH:mm"),
        to: to.format("D. M. YYYY HH:mm"),
        dt: Date.now(),
        loading: false,
        error: null
      };
      setItems(i);
    } catch (e) {
      LOG(tag, "refresh: error detected while gettings pickups, error: %O", e);
      setItems({
        ...initItems,
        loading: false,
        dt: Date.now(),
        error: i18next.t("view.Pickups.loadingPickupsError")
      });
    }
  }
  useEffect(() => {
    refresh();
  }, []);
  return <View navItem={navItem}>
            <Header />
            <Content>
                <Info municipalityName={settings.municipalityName} householdName={settings.householdName} from={items.from} to={items.to} />
                <Paper sx={{
        flex: "1 1 auto",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch"
      }}>
                    <Typography variant="body1" component="div" sx={{
          flex: "0 0 auto",
          textAlign: "left",
          fontWeight: "bold",
          paddingLeft: 2,
          paddingTop: 1
        }}>
                        {i18next.t("view.Pickups.pickupContainers")}
                    </Typography>
                    {/* Loading items */
        items.items.length === 0 && items.loading && <Loading colorPalette={colorPalette} />}
                    {/* Cannot load items - error */
        items.items.length === 0 && !items.loading && !!items.error && <LoadError colorPalette={colorPalette} error={items.error} refresh={refresh} />}
                    {/* No data */
        items.items.length === 0 && !items.loading && !items.error && <Empty colorPalette={colorPalette} />}
                    {/* Item list */
        items.items.length > 0 && <Virtuoso style={{
          flex: "1 1 auto"
        }} context={{
          dt: items.dt
        }} data={items.items} totalCount={items.items.length} itemContent={renderListItem} />}
                </Paper>
            </Content>
        </View>;
}
export default Pickups;
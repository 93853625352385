import dexie from "@db/dexie";
import transaction from "@db/util/transaction";
import { DBInstance } from "./typeDefs";
import table from "@db/table";
import { Table } from "@db/table/typeDefs";
const tag = "@lib/db";
export const open = async () => {
  await dexie.open();
  let keys = Object.keys(table);
  for (let key of keys) {
    let t = table[key];
    if (t.init instanceof Function) {
      await t.init();
    }
  }
  await table.Settings.set({
    dbInitialized: true
  });
};
export const db: DBInstance = {
  dexie,
  transaction,
  open,
  [Table.SETTINGS]: table.Settings
};
export default db;
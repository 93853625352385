import axios from "axios";
import moment from "moment-timezone";
const tag = "@api/getPickupCalendarEvents";
const url = "api/getPickupCalendarEvents";
export interface PickupCalendarEvent {
  id: number;
  description: string | null;
  municipalityID: number | null;
  municipalityName: string | null;
  wasteTypeCatalogID: number | null;
  wasteTypeCatalogName: string | null;
  recurrenceRule: string | null;
  recurrenceException: string | null;
  dtStarted: string | null;
  dtEnded: string | null;
  municipalitiesGroupID: string | null;
}
export const getPickupCalendarEvents = async (municipality: number, from, to): Promise<Array<PickupCalendarEvent>> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        municipality: [municipality],
        from,
        to
      }
    };
    /*
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            if (Math.random() > 0.5) {
                resolve(null);
            } else {
                DEBUG(tag, "rejecting");
                reject(null);
            }
        }, 3000);
    }) 
    */

    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data.appointments && res.data.appointments instanceof Array) {
      let ret = ([] as Array<any>).concat(res.data.appointments);
      ret = ret.map(item => ({
        ...item
      })).filter(item => typeof item.id === "number");
      ret.sort((a, b) => moment(a.dtStarted || "").diff(moment(b.dtEnded || "")));
      return (ret as Array<PickupCalendarEvent>);
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getPickupCalendarEvents;
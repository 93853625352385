import { SelectContext, initState } from "@component/core/Context/Select/SelectContext";
import { SelectContextState } from "@component/core/Context/Select/typeDefs";
import { useState } from "react";
import merge from "@lib/merge";
const tag = "@component/core/Context/Select/SelectContextProvider";
export function SelectContextProvider({
  children
}) {
  const [state, setState] = useState(initState);
  const data = {
    select: state,
    setSelect: (diff: Partial<SelectContextState>) => {
      const s = merge(initState, diff);
      setState(s);
    },
    mergeSelect: (diff: Partial<SelectContextState>) => {
      const s = merge(state, diff);
      setState(s);
    }
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.select = data;
  }
  return <SelectContext.Provider value={data}>
            {children}
        </SelectContext.Provider>;
}
export default SelectContextProvider;
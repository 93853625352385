export function Content({
  children,
  style = {}
}) {
  return <div style={{
    display: "flex",
    flex: "1 1 auto",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    overflow: "auto",
    ...style
  }}>
            {children}
        </div>;
}
export default Content;
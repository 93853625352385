import { NavigationContextStateHistoryItem, NavigationContextStateHistoryItemKey } from "@core/Context/Navigation/typeDefs";
import CalendarEvents from "@view/CalendarEvents";
import Drawer from "@component/core/Drawer";
import Household from "@component/view/Household";
import Intro from "@view/Intro";
import NavigationContext from "@core/Context/Navigation/NavigationContext";
import Notifications from "@view/Notifications";
import Pickups from "@component/view/Pickups";
import Progress from "@component/common/Progress";
import Pwa from "@common/Pwa";
import React, { useContext, useState } from "react";
import Report from "@view/Report";
import Select from "@component/common/Select";
import SetNotification from "@view/SetNotification";
import Settings from "@view/Settings";
function View({
  item
}: {
  item: NavigationContextStateHistoryItem;
}) {
  switch (item.key) {
    case NavigationContextStateHistoryItemKey.INTRO:
      return <Intro key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.CALENDAR_EVENTS:
      return <CalendarEvents key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.NOTIFICATIONS:
      return <Notifications key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.REPORT:
      return <Report key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.SETTINGS:
      return <Settings key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.SET_NOTIFICATION:
      return <SetNotification key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.HOUSEHOLD:
      return <Household key={item.uuid} navItem={item} />;
    case NavigationContextStateHistoryItemKey.PICKUPS:
      return <Pickups key={item.uuid} navItem={item} />;
    default:
      return <Intro key={item.uuid} navItem={item} />;
  }
}
export function Router() {
  const {
    navigation
  } = useContext(NavigationContext);
  return <React.Fragment>
            <div style={{
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "stretch",
      overflow: "auto"
    }}>
                <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        overflow: "auto",
        flex: "1 1 auto"
      }}>
                    <Drawer />
                    {navigation.history.map(item => <View item={item} key={item.uuid} />)}
                    <Select />
                    <Progress />
                    <Pwa />
                </div>
            </div>
        </React.Fragment>;
}
export default Router;
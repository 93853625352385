import { blue, green, yellow, grey, brown } from "@mui/material/colors";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CircularProgress } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { getNotifications } from "@api";
import { NavigationContextStateHistoryItemKey } from "@core/Context/Navigation/typeDefs";
import { themePalette } from "@theme";
import { Virtuoso } from "react-virtuoso";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import containerListItem from "@common/ContainerListItem";
import Content from "@common/Content";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import i18next from "i18next";
import IconButton from "@mui/material/IconButton";
import MenuButton from "@common/MenuButton";
import moment from "moment-timezone";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React, { useContext, useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import View from "@common/View";

//const localizer = momentLocalizer(moment);
const tag = "@component/view/Notifications";
const defaultContainerListItem = containerListItem["Směsný komunální odpad"];
export function NotificationListItem({
  item,
  colorPalette
}) {
  const {
    pushNavigation
  } = useContext(NavigationContext);
  const dt = moment(item.dtStarted).format("D. M. YYYY");
  const timeStart = moment(item.dtStarted).format("HH:mm");
  const timeEnd = moment(item.dtEnded).format("HH:mm");
  const containerListItemColor = containerListItem[item.wasteTypeCatalogName] || defaultContainerListItem;
  const h = moment(item.dtStarted).startOf("hour").subtract(item.beforeNotification, "hours").format("HH:mm");
  function edit() {
    pushNavigation({
      key: NavigationContextStateHistoryItemKey.SET_NOTIFICATION,
      data: {
        item,
        containerListItemColor
      }
    });
  }
  return <Box sx={{
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
    borderBottom: "1px solid " + colorPalette.divider
  }}>
            {/* Left */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "0 0 auto",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      paddingRight: "5px"
    }}>
                <DeleteIcon sx={{
        fontSize: "48px",
        color: containerListItemColor.color
      }} />
            </Box>

            {/* Center */}
            <Box sx={{
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      justifyContent: "center",
      alignItems: "stretch",
      padding: "10px",
      paddingLeft: "5px"
    }}>
                <Typography variant="body1" component="div" sx={{
        textAlign: "left",
        fontWeight: "bold"
      }}>
                    {item.wasteTypeCatalogName}
                </Typography>
                {!!item.description && <Typography variant="caption" component="div" sx={{
        textAlign: "left"
      }}>
                        <b>{i18next.t("view.Notifications.description")}: </b>{item.description ? item.description : ""}
                    </Typography>}
                <Typography variant="caption" component="div" sx={{
        textAlign: "left"
      }}>
                    <b>{i18next.t("view.Notifications.when")}: </b>{i18next.t("view.Notifications.whenText")} {h}
                </Typography>
                <Typography variant="caption" component="div" sx={{
        textAlign: "left"
      }}>
                    <b>{i18next.t("view.Notifications.on")}: </b>{item.wasteType ? i18next.t("view.Notifications.onType") : item.series ? i18next.t("view.Notifications.onAll") : i18next.t("view.Notifications.onCurrent")}        
                </Typography>
                <Typography variant="caption" component="div" sx={{
        textAlign: "left"
      }}>
                    <b>{i18next.t("view.Notifications.dt")}: </b>{dt} {timeStart} - {timeEnd}
                </Typography>
            </Box>

            {/* Right */}
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="info" onClick={edit}>
                <NotificationsIcon />
            </IconButton>
        </Box>;
}
function Header() {
  return <AppBar position="static">
            <Toolbar>
                <MenuButton />
                <Typography variant="h6" component="div" sx={{
        flexGrow: 1
      }}>
                    {i18next.t("view.Notifications.headerTitle")}
                </Typography>
            </Toolbar>
        </AppBar>;
}
function Loading({
  colorPalette
}) {
  return <Box sx={{
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Box sx={{
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: 90,
      height: 90
    }}>
                <CircularProgress size={60} variant="indeterminate" />
            </Box>
            <Typography variant="caption" component="div" color="text.secondary">
                {i18next.t("view.Notifications.loadingNotifications")}
            </Typography>
        </Box>;
}
function LoadError({
  colorPalette,
  error,
  refresh
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.error.main,
      textAlign: "center"
    }}>
                {error}
            </Typography>
            <Button variant="outlined" color="error" sx={{
      marginTop: 1,
      padding: 1.5
    }} startIcon={<RefreshIcon />} onClick={refresh}>
                {i18next.t("common.refresh")}
            </Button>
        </Box>;
}
function Empty({
  colorPalette
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center"
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.text.secondary,
      textAlign: "center"
    }}>
                {i18next.t("view.Notifications.noData")}
            </Typography>
        </Box>;
}
const initItems = {
  items: [],
  loading: true,
  dt: Date.now(),
  error: null
};
export function Notifications({
  navItem
}) {
  const [items, setItems] = useState(initItems);
  const {
    settings
  } = useContext(SettingsContext);
  const colorPalette = themePalette(settings.theme);
  function renderListItem(index, item) {
    return <NotificationListItem item={item} colorPalette={colorPalette} />;
  }
  async function refresh() {
    try {
      setItems(initItems);
      let n = (await getNotifications({
        uuidObserver: settings.uuidObserver,
        forObserver: true
      })).notifications;
      n.sort((a, b) => moment(a.dtStarted || "").diff(moment(b.dtEnded || "")));
      const i = {
        items: n,
        dt: Date.now(),
        loading: false,
        error: null
      };
      setItems(i);
    } catch (e) {
      LOG(tag, "refresh: error detected while gettings notifications, error: %O", e);
      setItems({
        ...initItems,
        loading: false,
        dt: Date.now(),
        error: i18next.t("view.Notifications.loadingNotificationsError")
      });
    }
  }
  useEffect(() => {
    refresh();
  }, [navItem]);
  return <View navItem={navItem}>
            <Header />
            <Content style={{
      backgroundColor: colorPalette.background.paper,
      padding: 0
    }}>
            {/* Loading items */
      items.items.length === 0 && items.loading && <Loading colorPalette={colorPalette} />}
            {/* Cannot load items - error */
      items.items.length === 0 && !items.loading && !!items.error && <LoadError colorPalette={colorPalette} error={items.error} refresh={refresh} />}
            {/* No data */
      items.items.length === 0 && !items.loading && !items.error && <Empty colorPalette={colorPalette} />}
            {/* Item list */
      items.items.length > 0 && <Virtuoso style={{
        flex: "1 1 auto"
      }} context={{
        dt: items.dt
      }} data={items.items} totalCount={items.items.length} itemContent={renderListItem} />}
            </Content>
        </View>;
}
export default Notifications;
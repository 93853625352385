import ProgressContext from "@component/core/Context/Progress/ProgressContext";
import React, { useContext } from "react";
import { Alert, Box, CircularProgress, Dialog, Slide, Snackbar, Typography } from "@mui/material";
const autoHideDuration = 3000;
export function Progress() {
  const {
    progress,
    setProgress
  } = useContext(ProgressContext);
  function closeSnack() {
    setProgress({});
  }
  return <React.Fragment>
            <Dialog open={progress.open && !progress.isSnack}>
                <Box sx={{
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 2
      }}>
                    <Box sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: 90,
          height: 90
        }}>
                        <CircularProgress size={60} variant={typeof progress.percentage === "number" ? "determinate" : "indeterminate"} value={progress.percentage !== null ? progress.percentage : undefined} />
                        {/* Percentage inside of circle */
          typeof progress.percentage === "number" && <Box sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                                    <Typography variant="caption" component="div" color="text.secondary">
                                        {`${progress.percentage | 0}%`}
                                    </Typography>
                                </Box>}
                    </Box>
                    {/* Progress text */
        progress.text && <Typography variant="caption" component="div" color="text.secondary">
                                {progress.text}
                            </Typography>}
                </Box>
            </Dialog>
            {/* Snackbar */
    progress.isSnack && !progress.alert && <Snackbar anchorOrigin={{
      vertical: "top",
      horizontal: "center"
    }} open={!!(progress.open && !!progress.isSnack && progress.text)} autoHideDuration={autoHideDuration} onClose={closeSnack} message={progress.text} />}
            {/* Alert snackbar */
    progress.isSnack && !!progress.alert && <Snackbar anchorOrigin={{
      vertical: "top",
      horizontal: "center"
    }} open={!!(progress.open && !!progress.isSnack && progress.text)} autoHideDuration={autoHideDuration} TransitionComponent={props => <Slide {...props} direction="down" />} onClose={closeSnack}>
                        <Alert onClose={closeSnack} severity={(progress.alert as any)} sx={{
        width: "100%"
      }}>
                            {progress.text}
                        </Alert>
                    </Snackbar>}
        </React.Fragment>;
}
export default Progress;
import { NavigationContextStateHistoryItemKey } from "@core/Context/Navigation/typeDefs";
import { themePalette } from "@theme";
import { Button, useMediaQuery } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Divider from "@mui/material/Divider";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import i18next from "i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MaterialDrawer from "@mui/material/Drawer";
import MenuContext from "@core/Context/Menu/MenuContext";
import NavigationContext from "@core/Context/Navigation/NavigationContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import React, { useContext } from "react";
import SendIcon from "@mui/icons-material/Send";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SettingsContext from "@core/Context/Settings/SettingsContext";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Typography from "@mui/material/Typography";
import isIOS from "@lib/util/isIOS";
import PwaContext from "@core/Context/Pwa/PwaContext";
declare const APP_VERSION: string;
const tag = "@component/core/Drawer";
export const drawerWidth: number = 250;
function MenuItem({
  title,
  navKey,
  SelectedIcon,
  Icon,
  colorPalette
}) {
  const {
    setMenu
  } = useContext(MenuContext);
  const {
    navigation,
    setNavigation
  } = useContext(NavigationContext);
  const selected = navigation.history.length > 0 ? navKey === navigation.history[navigation.history.length - 1].key : false;
  function navigate() {
    const nav = {
      history: [{
        active: true,
        key: navKey,
        uuid: uuidv4()
      }]
    };
    setNavigation(nav);
    setMenu({
      open: false
    });
  }
  return <React.Fragment>
            <ListItem disablePadding>
                <ListItemButton onClick={navigate} selected={selected}>
                    <ListItemIcon sx={{
          color: selected ? colorPalette.primary.main : colorPalette.text.main
        }}>
                        {selected && <SelectedIcon />}
                        {!selected && <Icon />}
                    </ListItemIcon>
                    <ListItemText disableTypography primary={<Typography sx={{
          color: selected ? colorPalette.primary.main : colorPalette.text.main,
          fontWeight: selected ? "bold" : "normal"
        }}>
                                {title}
                            </Typography>} />
                </ListItemButton>
            </ListItem>
            <Divider />
        </React.Fragment>;
}
function CalendarEvents({
  colorPalette
}) {
  return <MenuItem title={i18next.t("Drawer.calendarEvents")} SelectedIcon={CalendarMonthIcon} Icon={CalendarMonthOutlinedIcon} navKey={NavigationContextStateHistoryItemKey.CALENDAR_EVENTS} colorPalette={colorPalette} />;
}
function Notifications({
  colorPalette
}) {
  return <MenuItem title={i18next.t("Drawer.notifications")} SelectedIcon={NotificationsIcon} Icon={NotificationsOutlinedIcon} navKey={NavigationContextStateHistoryItemKey.NOTIFICATIONS} colorPalette={colorPalette} />;
}
function Household({
  colorPalette
}) {
  return <MenuItem title={i18next.t("Drawer.household")} SelectedIcon={HomeIcon} Icon={HomeOutlinedIcon} navKey={NavigationContextStateHistoryItemKey.HOUSEHOLD} colorPalette={colorPalette} />;
}
function Pickups({
  colorPalette
}) {
  return <MenuItem title={i18next.t("Drawer.pickups")} SelectedIcon={LocalShippingIcon} Icon={LocalShippingOutlinedIcon} navKey={NavigationContextStateHistoryItemKey.PICKUPS} colorPalette={colorPalette} />;
}
function Report({
  colorPalette
}) {
  return <MenuItem title={i18next.t("Drawer.report")} SelectedIcon={SendIcon} Icon={SendOutlinedIcon} navKey={NavigationContextStateHistoryItemKey.REPORT} colorPalette={colorPalette} />;
}
function Settings({
  colorPalette
}) {
  return <MenuItem title={i18next.t("Drawer.settings")} SelectedIcon={SettingsIcon} Icon={SettingsOutlinedIcon} navKey={NavigationContextStateHistoryItemKey.SETTINGS} colorPalette={colorPalette} />;
}
export function Drawer() {
  const desktop = useMediaQuery(`(min-width:${600 + drawerWidth}px)`);
  const {
    navigation
  } = useContext(NavigationContext);
  const {
    settings
  } = useContext(SettingsContext);
  const {
    menu,
    setMenu
  } = useContext(MenuContext);
  const {
    pwa,
    mergePwa
  } = useContext(PwaContext);
  const colorPalette = themePalette(settings.theme);
  const ios = isIOS();
  const standalone = useMediaQuery("(display-mode: standalone)");
  function closeMenu() {
    setMenu({
      open: false
    });
  }
  async function install() {
    if (!ios && !standalone && !!pwa.prompt && pwa.prompt.prompt) {
      pwa.prompt.prompt();
      const {
        outcome
      } = await pwa.prompt.userChoice;
      mergePwa({
        prompt: null
      });
      if (outcome === "accepted") {} else if (outcome === "dismissed") {}
      closeMenu();
    } else if (!!ios) {
      mergePwa({
        showInstall: false,
        showIOSInstallInfo: true
      });
      closeMenu();
    }
  }
  return <MaterialDrawer sx={desktop ? {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      position: "relative"
    }
  } : {}} variant={desktop && navigation.history.length > 0 && navigation.history[navigation.history.length - 1].key !== NavigationContextStateHistoryItemKey.INTRO ? "permanent" : "temporary"} anchor="left" open={menu.open} onClose={closeMenu}>
            <Box sx={{
      width: drawerWidth,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "flex-start"
    }} role="presentation">
                <img style={{
        maxHeight: "83px",
        marginTop: "10px",
        alignSelf: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: 230,
        height: 52,
        objectFit: "contain"
      }} src="logo.png" />

                {/* Menu items */}
                <List>
                    <CalendarEvents colorPalette={colorPalette} />
                    {!!settings.uuidObserver && <Notifications colorPalette={colorPalette} />}
                    {typeof settings.householdID === "number" && <Household colorPalette={colorPalette} />}
                    {typeof settings.householdID === "number" && <Pickups colorPalette={colorPalette} />}
                    {!!settings.useReport && <Report colorPalette={colorPalette} />}
                    <Settings colorPalette={colorPalette} />
                </List>

                <div style={{
        flex: "1 1 auto"
      }}></div>

                {/* Installation */
      !standalone && (ios || pwa.prompt && pwa.prompt.prompt) && <Button sx={{
        marginBottom: "14px",
        padding: 1.5,
        margin: 1
      }} onClick={install} variant="outlined">
                            {ios ? i18next.t("Drawer.app.howToInstall") : i18next.t("Drawer.app.install")}
                        </Button>}

                {/* Version info */}
                <Typography variant="caption" sx={{
        alignSelf: "center",
        margin: 1,
        color: colorPalette.text.secondary
      }}>
                    v{APP_VERSION}
                </Typography>
            </Box>
        </MaterialDrawer>;
}
export default Drawer;
import upsert from "@db/util/upsert";
import { PromiseExtended } from "dexie";

/**
 * Set (upsert) the first item in DB table.
 * 
 * @param tableName name of DB table
 * @param data 
 * @param pk primary key (default = `id`)
 * @returns primary key value of set item (always 1)
 */
export const set = (tableName: string, data: any, pk: string = "id"): PromiseExtended<number> => {
  return upsert(tableName, {
    ...data,
    [pk]: 1
  }, pk);
};
export default set;
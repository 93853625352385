import { desktopWidth } from "@lib/desktop";
import { getHouseholds, getMunicipalities } from "@api";
import { Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Content from "@common/Content";
import i18next from "i18next";
import CheckIcon from '@mui/icons-material/Check';
import MenuButton from "@common/MenuButton";
import notifications from "@lib/notifications";
import notificationsInfo from "@lib/notifications/info";
import React from "react";
import SelectContext from "@component/core/Context/Select/SelectContext";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import View from "@common/View";
import isIOS from "@lib/util/isIOS";
import { useMediaQuery } from "@mui/material";
import PwaContext from "@component/core/Context/Pwa/PwaContext";
const tag = "@component/view/Settings";
function Header() {
  return <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <MenuButton />
                    <Typography variant="h6" component="div" sx={{
          flexGrow: 1
        }}>
                        {i18next.t("view.Settings.headerTitle")}
                    </Typography>
                </Toolbar>
            </AppBar>
        </React.Fragment>;
}
export function Settings({
  navItem
}) {
  const [notificationPermission, setNotificationPermission] = useState(notificationsInfo.permission);
  const {
    setSelect
  } = useContext(SelectContext);
  const {
    pwa,
    mergePwa
  } = useContext(PwaContext);
  const {
    settings,
    mergeSettings
  } = useContext(SettingsContext);
  const ios = isIOS();
  const standalone = useMediaQuery("(display-mode: standalone)");
  useEffect(() => {
    notificationsInfo.emitter.on("change", info => {
      setNotificationPermission(info.permission);
    });
  }, []);
  async function onMunicipalityClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getMunicipalities()).map(item => ({
        ...item,
        key: item.id,
        text: item.name
      })),
      loadingText: i18next.t("view.Settings.loadMunicipalities.loading"),
      loadErrorText: i18next.t("view.Settings.loadMunicipalities.error"),
      noDataText: i18next.t("view.Settings.loadMunicipalities.noData"),
      selected: settings.municipalityID,
      onSelect: async item => {
        await mergeSettings({
          municipalityID: item.key,
          municipalityName: item.name,
          householdID: null,
          householdName: null
        });
      }
    });
  }
  async function onHouseholdClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getHouseholds(settings.municipalityID)).map(item => ({
        ...item,
        key: item.id,
        text: item.name
      })),
      loadingText: i18next.t("view.Settings.loadHouseholds.loading"),
      loadErrorText: i18next.t("view.Settings.loadHouseholds.error"),
      noDataText: i18next.t("view.Settings.loadHouseholds.noData"),
      selected: settings.householdID,
      onSelect: async item => {
        await mergeSettings({
          householdID: item.key,
          householdName: item.name
        });
      }
    });
  }
  async function enableNotifications() {
    if (notificationPermission === "granted") {
      return;
    }
    if (!!ios && !standalone) {
      mergePwa({
        showInstall: false,
        showIOSInstallInfo: true
      });
      return;
    }
    if (notificationsInfo.ready && notificationsInfo.permission !== "granted") {
      notifications.requestNotificationPermission();
    }
  }
  async function install() {
    if (!ios && !standalone && !!pwa.prompt && pwa.prompt.prompt) {
      pwa.prompt.prompt();
      const {
        outcome
      } = await pwa.prompt.userChoice;
      mergePwa({
        prompt: null
      });
      if (outcome === "accepted") {} else if (outcome === "dismissed") {}
    } else if (!!ios) {
      mergePwa({
        showInstall: false,
        showIOSInstallInfo: true
      });
    }
  }
  return <View navItem={navItem}>
            <Header />
            <Content>
                <Paper sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        maxWidth: desktopWidth
      }}>
                    {/* Municipality */}
                    <Typography variant="body1" sx={{
          fontWeight: "bold",
          paddingBottom: 1
        }}>
                        {`${i18next.t("view.Settings.municipality")} *`}
                    </Typography>
                    <Button sx={{
          marginBottom: "14px",
          padding: 1.5
        }} onClick={onMunicipalityClick} variant="contained">
                        {settings.municipalityName ? settings.municipalityName : i18next.t("common.unfilled")}
                    </Button>
                 
                    {/* Household */}
                    <Typography variant="body1" sx={{
          fontWeight: "bold",
          paddingBottom: 1
        }}>
                        {i18next.t("view.Settings.household")}
                    </Typography>
                    <Button sx={{
          marginBottom: "14px",
          padding: 1.5
        }} disabled={typeof settings.municipalityID !== "number"} onClick={onHouseholdClick} variant="contained">
                        {settings.householdName ? settings.householdName : i18next.t("common.unfilled")}
                    </Button>

                    {/* Installation */
        !standalone && (ios || pwa.prompt && pwa.prompt.prompt) && <React.Fragment>
                                <Typography variant="body1" sx={{
            fontWeight: "bold",
            paddingBottom: 1
          }}>
                                    {i18next.t("view.Settings.app.title")}
                                </Typography>
                                <Button sx={{
            marginBottom: "14px",
            padding: 1.5
          }} onClick={install} variant="contained">
                                    {ios ? i18next.t("view.Settings.app.howToInstall") : i18next.t("view.Settings.app.install")}
                                </Button>
                            </React.Fragment>}

                    {/* Notifications */}
                    <Typography variant="body1" sx={{
          fontWeight: "bold",
          paddingBottom: 1
        }}>
                        {i18next.t("view.Settings.notifications")}
                    </Typography>
                    <Button sx={{
          marginBottom: "14px",
          padding: 1.5
        }} onClick={enableNotifications} color={notificationPermission === "granted" ? "success" : ios && !standalone ? "error" : "primary"} variant="contained">
                        {notificationPermission === "granted" ? i18next.t("view.Settings.enabled") : ios && !standalone ? i18next.t("view.Settings.disabled") : i18next.t("view.Settings.enable")}
                    </Button>
                </Paper>
            </Content>
        </View>;
}
export default Settings;
import dexie from "@db/dexie";
import { PromiseExtended } from "dexie";
const tag = "@lib/db/util/upsert";

/**
 * Upsert item to DB.
 * 
 * @param tableName name of DB table 
 * @param data 
 * @param pk primary key (default = `id`)
 * @returns primary key value of upserted item
 */
export const upsert = (tableName: string, data: any, pk: string = "id"): PromiseExtended<number> => {
  const dbInstance = dexie[tableName];
  const dataRest = {
    ...data
  };
  const primaryKey = typeof data[pk] !== "undefined" && data[pk] !== null ? data[pk] : null;
  if (primaryKey === null) {
    return dbInstance.add(dataRest);
  }
  return dbInstance.where({
    [pk]: primaryKey
  }).first().then((item: any | undefined) => {
    if (!item) {
      return dbInstance.add(dataRest);
    }
    return dbInstance.update(item[pk], dataRest).then(() => item[pk]);
  });
};
export default upsert;
import { createContext } from "react";
import { PwaContextState } from "@component/core/Context/Pwa/typeDefs";
import { info as windowInfo } from "@lib/window";
const tag = "@component/core/Context/Pwa/PwaContext";
export const initState: PwaContextState = {
  showInstall: false,
  showIOSInstallInfo: false,
  prompt: windowInfo.prompt
};
export const PwaContext = createContext({
  pwa: initState,
  setPwa: (diff: Partial<PwaContextState>) => {},
  mergePwa: (diff: Partial<PwaContextState>) => {}
});
export default PwaContext;
import axios from "axios";
const tag = "@api/createObserver";
const url = "api/createObserver";
export const createObserver = async (subscription, municipalityID): Promise<string> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        endPoint: subscription.endpoint,
        keys: subscription,
        municipalityID,
        active: true
      }
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data.uuidObserver) {
      return res.data.uuidObserver;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default createObserver;
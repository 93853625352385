import axios from "axios";
const tag = "@api/getContainerReportTypes";
const url = "api/getContainerReportTypes";
export interface ContainerReportType {
  id: number;
  name: string | null;
}
export const getContainerReportTypes = async (): Promise<Array<ContainerReportType>> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {}
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data instanceof Array) {
      let ret = ([] as Array<any>).concat(res.data);
      ret = ret.map(item => ({
        ...item
      })).filter(item => typeof item.id === "number");
      ret.sort((a, b) => (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase()));
      return (ret as Array<ContainerReportType>);
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getContainerReportTypes;
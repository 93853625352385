import dexie from "@db/dexie";
import { Table, Transaction, TransactionMode, PromiseExtended } from "dexie";

/**
 * DB transaction.
 * 
 * @param tables DB tables used in transaction
 * @param cb function to execute with the transaction
 * @param mode transaction mode, default = `rw` (`r` = readonly, `rw` = readwrite)
 * @returns promise with return value of executed function (cb)
 */
export const transaction = (tables: Array<Table>, cb: (trx: Transaction) => any, mode: TransactionMode = "rw"): PromiseExtended<any> => {
  return dexie.transaction(mode, tables, cb);
};
export default transaction;
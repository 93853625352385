// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nZDOGAJU9pjPe9A5nOrR{display:flex;flex:1 1 auto;flex-direction:column;justify-content:flex-start;align-items:stretch;overflow:auto;width:100%;height:100%}.dO5Aap_KkxDr9itpp5Q5{display:none}`, "",{"version":3,"sources":["webpack://./src/web/component/common/View/styles.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,aAAA,CACA,qBAAA,CACA,0BAAA,CACA,mBAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CAGJ,sBACI,YAAA","sourcesContent":[".active {\r\n    display: flex;\r\n    flex: 1 1 auto;\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n    align-items: stretch;\r\n    overflow: auto;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.inactive {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `nZDOGAJU9pjPe9A5nOrR`,
	"inactive": `dO5Aap_KkxDr9itpp5Q5`
};
export default ___CSS_LOADER_EXPORT___;

import { Box, Button, CircularProgress, MenuItem, Paper, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Content from "@common/Content";
import createNotification from "@api/createNotification";
import DeleteIcon from "@mui/icons-material/Delete";
import editNotification from "@api/editNotification";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import getNotifications from "@api/getNotifications";
import i18next from "i18next";
import IconButton from "@mui/material/IconButton";
import moment from "moment-timezone";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import ProgressContext from "@component/core/Context/Progress/ProgressContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import removeNotification from "@api/removeNotification";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import View from "@common/View";
import config from "@config";
const tag = "@component/view/SetNotification";
function Header({
  cancel,
  saving,
  save,
  hourNotification,
  dayNotification
}) {
  return <AppBar position="static">
            <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{
        mr: 2
      }} onClick={cancel}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{
        flexGrow: 1
      }}>
                    {i18next.t("view.SetNotification.headerTitle")}
                </Typography>
                <IconButton size="large" color="inherit" onClick={save}>
                    {!saving && <CheckIcon />}
                    {!!saving && <CircularProgress size={20} color="inherit" variant="indeterminate" />}
                </IconButton>
            </Toolbar>
        </AppBar>;
}
export function SetNotification({
  navItem
}) {
  const {
    settings
  } = useContext(SettingsContext);
  const {
    setProgress
  } = useContext(ProgressContext);
  const {
    backNavigation
  } = useContext(NavigationContext);
  const item = navItem.data.item;
  let dtStart: any = moment(navItem.data.item.dtStarted);
  if (!!settings.showEventTime) {
    dtStart = dtStart.format("D. M. YYYY HH:mm");
  } else {
    dtStart = dtStart.format("D. M. YYYY");
  }
  const dtEnd = moment(navItem.data.item.dtEnded).format("D. M. YYYY HH:mm");
  const [notifications, setNotifications] = useState([]);
  const [notifyOn, setNotifyOn] = useState("current");
  const [saving, setSaving] = useState(false);
  const [hourNotification, setHourNotification] = useState(null);
  const [dayNotification, setDayNotification] = useState(null);
  const hours = [];
  let initialNotifyWhenHoursCnt = -1;
  for (let i = 0; i < 24; i++) {
    if (notifyOn === "current" && moment().startOf("hour").diff(moment(item.dtStarted).startOf("day").subtract(1, "day").add(i, "hours"), "hours") >= 0) {
      continue;
    }
    hours.push({
      value: moment(item.dtStarted).startOf("hour").diff(moment(item.dtStarted).startOf("day").subtract(1, "day").add(i, "hours"), "hours"),
      text: `${i < 10 ? "0" : ""}${i}:00`
    });
    if (i === config.initialNotifyWhenHours) {
      initialNotifyWhenHoursCnt = hours[hours.length - 1].value;
    }
  }
  if (initialNotifyWhenHoursCnt < 0 && hours.length > 0) {
    initialNotifyWhenHoursCnt = hours[0].value;
  }
  const [notifyWhenHoursCnt, setNotifyWhenHoursCnt] = useState(initialNotifyWhenHoursCnt);
  function onNotifyWhenHoursCntChange(event) {
    setNotifyWhenHoursCnt(event.target.value);
  }
  function cancel() {
    backNavigation();
  }
  async function createDayNotification() {
    const observer = settings.uuidObserver;
    try {
      const res = await createNotification({
        uuidObserver: observer,
        beforeNotification: notifyWhenHoursCnt,
        eventID: item.id,
        wasteType: notifyOn === "type",
        municipalityID: item.municipalityID,
        series: notifyOn === "all",
        dtStarted: item.dtStarted,
        dtEnded: item.dtEnded
      });
    } catch (e) {
      LOG(tag, "createDayNotification: error detected while creating day notification, error: %O", e);
      throw e;
    }
  }
  async function editDayNotification() {
    const observer = settings.uuidObserver;
    try {
      const res = await editNotification({
        uuidObserver: observer,
        id: dayNotification.notificationChannelID,
        beforeNotification: notifyWhenHoursCnt,
        eventID: item.id,
        wasteType: notifyOn === "type",
        municipalityID: item.municipalityID,
        series: notifyOn === "all",
        dtStarted: item.dtStarted,
        dtEnded: item.dtEnded
      });
    } catch (e) {
      LOG(tag, "editDayNotification: error detected while editing day notification, error: %O", e);
      throw e;
    }
  }
  async function removeDayNotification() {
    const observer = settings.uuidObserver;
    try {
      const res = await removeNotification({
        uuidObserver: observer,
        channelID: dayNotification.notificationChannelID
      });
    } catch (e) {
      LOG(tag, "removeDayNotification: error detected while deleting day notification, error: %O", e);
      throw e;
    }
  }
  async function saveDayNotification() {
    if (dayNotification) {
      await removeDayNotification();
    }
    await createDayNotification();
  }
  async function save() {
    if (saving || notifyWhenHoursCnt < 0) {
      return;
    }
    setSaving(true);
    try {
      await saveDayNotification();
      setSaving(false);
      setProgress({
        open: true,
        text: i18next.t("view.SetNotification.saveSuccess"),
        isSnack: true,
        alert: "success"
      });
      backNavigation();
    } catch (e) {
      setSaving(false);
      setProgress({
        open: true,
        text: i18next.t("view.SetNotification.saveError"),
        isSnack: true,
        alert: "error"
      });
    }
  }
  async function deleteNotifications() {
    if (saving) {
      return;
    }
    setSaving(true);
    try {
      if (dayNotification) {
        await removeDayNotification();
      }
      setSaving(false);
      setProgress({
        open: true,
        text: i18next.t("view.SetNotification.saveSuccess"),
        isSnack: true,
        alert: "success"
      });
      backNavigation();
    } catch (e) {
      setSaving(false);
      setProgress({
        open: true,
        text: i18next.t("view.SetNotification.saveError"),
        isSnack: true,
        alert: "error"
      });
    }
  }
  function notifyOnChange(ev) {
    if (notifyOn === ev.target.value) {
      return;
    }
    if (ev.target.value === "current" && moment().startOf("hour").diff(moment(item.dtStarted).startOf("hour").subtract(notifyWhenHoursCnt, "hours"), "hours") >= 0) {
      setNotifyWhenHoursCnt(moment(item.dtStarted).startOf("hour").diff(moment().endOf("hour"), "hours"));
    }
    setNotifyOn(ev.target.value);
  }
  async function refresh() {
    let n = [];
    try {
      n = (await getNotifications({
        uuidObserver: settings.uuidObserver,
        forObserver: true,
        eventID: item.id,
        dtStarted: item.dtStarted,
        dtEnded: item.dtEnded
      })).notifications;
      n.sort((a, b) => moment(a.dtStarted || "").diff(moment(b.dtEnded || "")));
      setNotifications(n);
    } catch (e) {
      LOG(tag, "refresh: error detected while gettings notifications, error: %O", e);
    }
    for (let i = 0; i < n.length; i++) {
      let item = n[i];
      if (item.wasteType) {
        setNotifyOn("type");
      } else if (item.series) {
        setNotifyOn("all");
      } else {
        setNotifyOn("current");
      }
      setNotifyWhenHoursCnt(item.beforeNotification);
      setDayNotification(item);
    }
  }
  useEffect(() => {
    refresh();
  }, [navItem]);
  return <View navItem={navItem}>
            <Header dayNotification={dayNotification} hourNotification={hourNotification} cancel={cancel} saving={saving} save={save} />
            <Content>
                {/* Info */}
                <Paper sx={{
        backgroundColor: navItem.data.containerListItemColor.background,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 2,
        marginBottom: 1
      }}>
                    <Typography variant="h6" component="div">
                        {item.wasteTypeCatalogName}
                    </Typography>
                    {!settings.showEventTime && <Typography variant="body1" component="div">
                            <b>{i18next.t("view.SetNotification.info.dt")}:</b> {dtStart}
                        </Typography>}
                    {!!settings.showEventTime && <Typography variant="body1" component="div">
                            <b>{i18next.t("view.SetNotification.info.from")}:</b> {dtStart}
                        </Typography>}
                    {!!settings.showEventTime && <Typography variant="body1" component="div">
                            <b>{i18next.t("view.SetNotification.info.to")}:</b> {dtEnd}
                        </Typography>}
                    <Typography variant="body1" component="div">
                        <b>{i18next.t("view.SetNotification.info.description")}:</b> {item.municipalityName} - {item.wasteTypeCatalogName}{item.description ? ` - ${item.description}` : ""}
                    </Typography>
                </Paper>

                {/* Notify when ...*/}
                {<Paper sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 2,
        marginBottom: 1
      }}>
                        <Typography variant="h6" component="div">
                            {i18next.t("view.SetNotification.notifyWhen")}
                        </Typography>
                        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 1,
          gap: 1
        }}>
                            <Typography variant="body1" component="div">
                                {i18next.t("view.SetNotification.notifyWhenDayAdvance")}
                            </Typography>
                            <Select value={notifyWhenHoursCnt} onChange={onNotifyWhenHoursCntChange}>
                                {hours.map(h => <MenuItem key={String(h.value)} value={h.value}>{h.text}</MenuItem>)}
                            </Select>
                        </Box>
                    </Paper>}

                {/* Notify on ...*/}
                <Paper sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 2
      }}>
                    <Typography variant="h6" component="div">
                        {i18next.t("view.SetNotification.notifyOn")}
                    </Typography>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" value={notifyOn} onChange={notifyOnChange}>
                        <FormControlLabel value="current" control={<Radio />} label={i18next.t("view.SetNotification.notifyOnCurrent")} />
                        {!!item.recurrenceRule && <FormControlLabel value="all" control={<Radio />} label={i18next.t("view.SetNotification.notifyOnAll")} />}
                        <FormControlLabel value="type" control={<Radio />} label={i18next.t("view.SetNotification.notifyOnType")} />
                    </RadioGroup>
                </Paper>

                {(hourNotification || dayNotification) && <Button variant="contained" color="error" sx={{
        marginTop: 1,
        padding: 1.5
      }} startIcon={<DeleteIcon />} onClick={deleteNotifications}>
                        {i18next.t("view.SetNotification.remove")}
                    </Button>}

            </Content>
        </View>;
}
export default SetNotification;
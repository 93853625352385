import axios from "axios";
const tag = "@api/getPublicVapidKey";
const url = "api/getPublicVapidKey";
export const getPickups = async (): Promise<string> => {
  try {
    const aopts = {
      method: "get",
      url,
      headers: {
        "accept": "application/json"
      }
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getPickups;
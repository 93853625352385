import { Box } from "@mui/material";
import { desktopWidth } from "@lib/desktop";
import { getHouseholds, getMunicipalities } from "@api";
import { NavigationContextStateHistoryItemKey } from "@core/Context/Navigation/typeDefs";
import { themePalette } from "@theme";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from "@mui/material/Button";
import Content from "@common/Content";
import i18next from "i18next";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import notifications from "@lib/notifications";
import notificationsInfo from "@lib/notifications/info";
import SelectContext from "@component/core/Context/Select/SelectContext";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Typography from "@mui/material/Typography";
import View from "@common/View";
const tag = "@component/view/Intro";
export function Intro({
  navItem
}) {
  const {
    setNavigation
  } = useContext(NavigationContext);
  const {
    setSelect
  } = useContext(SelectContext);
  const {
    settings,
    mergeSettings
  } = useContext(SettingsContext);
  const colorPalette = themePalette(settings.theme);
  async function onMunicipalityClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getMunicipalities()).map(item => ({
        ...item,
        key: item.id,
        text: item.name
      })),
      loadingText: i18next.t("view.Intro.loadMunicipalities.loading"),
      loadErrorText: i18next.t("view.Intro.loadMunicipalities.error"),
      noDataText: i18next.t("view.Intro.loadMunicipalities.noData"),
      selected: settings.municipalityID,
      onSelect: async item => {
        await mergeSettings({
          municipalityID: item.key,
          municipalityName: item.name,
          householdID: null,
          householdName: null
        });
      }
    });
  }
  async function onHouseholdClick() {
    setSelect({
      open: true,
      loadItems: async () => (await getHouseholds(settings.municipalityID)).map(item => ({
        ...item,
        key: item.id,
        text: item.name
      })),
      loadingText: i18next.t("view.Intro.loadHouseholds.loading"),
      loadErrorText: i18next.t("view.Intro.loadHouseholds.error"),
      noDataText: i18next.t("view.Intro.loadHouseholds.noData"),
      selected: settings.householdID,
      onSelect: async item => {
        await mergeSettings({
          householdID: item.key,
          householdName: item.name
        });
      }
    });
  }
  function navigateToCalendarEvents() {
    if (notificationsInfo.ready) {
      notifications.requestNotificationPermission();
    }
    setNavigation({
      history: [{
        active: true,
        key: NavigationContextStateHistoryItemKey.CALENDAR_EVENTS,
        uuid: uuidv4()
      }]
    });
  }
  return <View navItem={navItem}>
            <Content style={{
      backgroundColor: colorPalette.background.paper,
      justifyContent: "center"
    }}>
                <Box style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        overflow: "auto",
        backgroundColor: colorPalette.background.paper,
        padding: "20px",
        maxWidth: desktopWidth,
        alignSelf: "center"
      }}>
                    <img style={{
          maxHeight: "83px",
          alignSelf: "center",
          maxWidth: "100%"
        }} src="logo.png" />
                    <Typography sx={{
          textAlign: "center",
          marginTop: "40px",
          marginBottom: "40px"
        }} variant="body1">
                        {i18next.t("view.Intro.text")}
                    </Typography>
                    <Button sx={{
          marginBottom: "14px",
          padding: 1.5
        }} onClick={onMunicipalityClick} variant="contained">
                        {settings.municipalityName ? settings.municipalityName : i18next.t("view.Intro.selectMunicipality")}
                    </Button>
                    <Button sx={{
          marginBottom: "14px",
          padding: 1.5
        }} disabled={typeof settings.municipalityID !== "number"} onClick={onHouseholdClick} variant="contained">
                        {settings.householdName ? settings.householdName : i18next.t("view.Intro.selectHousehold")}
                    </Button>
                    <Button sx={{
          padding: 1.5
        }} variant="contained" disabled={typeof settings.municipalityID !== "number"} endIcon={<ArrowForwardIcon />} onClick={navigateToCalendarEvents}>
                        {i18next.t("common.continue")}
                    </Button>
                </Box>
            </Content>
        </View>;
}
export default Intro;
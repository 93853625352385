import axios from "axios";
const tag = "@api/getContainerInstances";
const url = "api/getContainerInstances";
export interface ContainerInstance {
  id: number;
  text: string | null;
  wasteTypeCatalogName: string | null;
}
export const getPickupCalendarEvents = async (householdID: number): Promise<Array<ContainerInstance>> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        householdID
      }
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data instanceof Array) {
      let ret = ([] as Array<any>).concat(res.data);
      ret = ret.map(item => ({
        ...item
      })).filter(item => typeof item.id === "number");
      ret.sort((a, b) => b.id - a.id);
      return (ret as Array<ContainerInstance>);
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getPickupCalendarEvents;
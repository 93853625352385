import { Table as DexieTable, PromiseExtended } from "dexie";
import { SortOption, SliceOption } from "@db/util/typeDefs";
export enum Table {
  SETTINGS = "Settings",
}
interface TableInstance {
  dexie: DexieTable;
}
export interface TableInstanceDefault<T> extends TableInstance {
  getAll: (filter?: (obj: T) => boolean, sort?: SortOption, slice?: SliceOption) => PromiseExtended<Array<T>>;
  add: (data: T) => PromiseExtended<any>;
  bulkAdd: (data: Array<T>, allKeys?: boolean) => PromiseExtended<any | Array<any>>;
  delete: (filter?: (obj: T) => boolean) => PromiseExtended<number>;
  upsert: (data: T) => PromiseExtended<any>;
  [x: string]: any;
}
export interface TableInstanceSet<T> extends TableInstance {
  get: () => PromiseExtended<T>;
  set: (data: T, pk?: string) => PromiseExtended<any>;
  init: () => any;
}
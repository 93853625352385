import axios from "axios";
const tag = "@api/removeObserver";
const url = "api/removeObserver";
export const removeObserver = async (observer): Promise<void> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        uuidObserver: observer
      }
    };
    let res = await axios(aopts);
    if (res.status === 200) {
      return;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default removeObserver;
import { createContext } from "react";
import { SelectContextState } from "@component/core/Context/Select/typeDefs";
export const initState: SelectContextState = {
  open: false,
  loadItems: () => Promise.resolve([]),
  loadingText: "",
  loadErrorText: "",
  noDataText: "",
  renderItem: null,
  selected: null,
  onSelect: null,
  searchID: null
};
export const SelectContext = createContext({
  select: initState,
  setSelect: (diff: Partial<SelectContextState>) => {},
  mergeSelect: (diff: Partial<SelectContextState>) => {}
});
export default SelectContext;
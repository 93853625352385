import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import ContextProvider from "@core/Context/ContextProvider";
import CssBaseline from '@mui/material/CssBaseline';
import lightTheme from "@theme/light";
import Router from "@core/Router";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
export function App() {
  return <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ContextProvider>
                    <Router />
                </ContextProvider>
            </LocalizationProvider>
        </ThemeProvider>;
}
export default App;
import add from "@db/util/add";
import bulkAdd from "@db/util/bulkAdd";
import bulkUpsert from "@db/util/bulkUpsert";
import del from "@db/util/delete";
import get from "@db/util/get";
import getAll from "@db/util/getAll";
import transaction from "@db/util/transaction";
import upsert from "@db/util/upsert";
import set from "@db/util/set";
export default {
  add,
  bulkAdd,
  bulkUpsert,
  delete: del,
  get,
  getAll,
  transaction,
  upsert,
  set
};
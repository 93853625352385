import { NavigationContextStateHistoryItemKey } from "@component/core/Context/Navigation/typeDefs";
import { SettingsContext, initState } from "@component/core/Context/Settings/SettingsContext";
import { SettingsContextState } from "@component/core/Context/Settings/typeDefs";
import { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import db from "@lib/db";
import merge from "@lib/merge";
import notificationsInfo from "@lib/notifications/info";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import config from "@config";
import { getHouseholdSettings } from "@api";
import updateHouseholdSettings, { updateHouseholdSettingsEmitter } from "@lib/util/updateHouseholdSettings";
import editObserver from "@api/editObserver";
const tag = "@component/core/Context/Settings/SettingsContextProvider";
const info = {
  dt: Date.now()
};
export function SettingsContextProvider({
  children
}) {
  const {
    navigation,
    setNavigation
  } = useContext(NavigationContext);
  const [state, setState] = useState(initState);
  const [] = useState();
  useEffect(() => {
    notificationsInfo.emitter.on("observer", async info => {
      const s = await db.Settings.get();
      setState({
        ...s,
        uuidObserver: info.observer
      });
    });
    updateHouseholdSettingsEmitter.on("update", settings => {
      setState(settings);
    });
    window.addEventListener("appinstalled", async () => {
      await db.Settings.set({
        pwaInstalled: true
      });
      const s = await db.Settings.get();
      setState(s);
    });
    const run = async () => {
      const s = await db.Settings.get();
      setState({
        ...s
      });
      if (typeof s.municipalityID === "number") {
        setNavigation({
          history: [{
            active: true,
            key: NavigationContextStateHistoryItemKey.CALENDAR_EVENTS,
            uuid: uuidv4()
          }]
        });
      } else {
        setNavigation({
          history: [{
            active: true,
            key: NavigationContextStateHistoryItemKey.INTRO,
            uuid: uuidv4()
          }]
        });
      }
    };
    run();
  }, []);
  const data = {
    settings: state,
    setSettings: async (diff: Partial<SettingsContextState>) => {
      const s = merge(initState, diff);
      if (notificationsInfo.subscription && typeof s.municipalityID === "number" && s.uuidObserver && typeof s.municipalityID !== "undefined" && s.municipalityID !== state.municipalityID) {
        await editObserver(s.uuidObserver, notificationsInfo.subscription, s.municipalityID);
      }
      setState(s);
      await db.Settings.set(diff);
      if (state.municipalityID !== diff.municipalityID && typeof diff.municipalityID !== "undefined") {
        updateHouseholdSettings();
      }
    },
    mergeSettings: async (diff: Partial<SettingsContextState>) => {
      const s = merge(state, diff);
      if (notificationsInfo.subscription && typeof s.municipalityID === "number" && s.uuidObserver && typeof s.municipalityID !== "undefined" && s.municipalityID !== state.municipalityID) {
        await editObserver(s.uuidObserver, notificationsInfo.subscription, s.municipalityID);
      }
      setState(s);
      await db.Settings.set(diff);
      if (state.municipalityID !== diff.municipalityID && typeof diff.municipalityID !== "undefined") {
        updateHouseholdSettings();
      }
    }
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.settings = data;
  }
  return <SettingsContext.Provider value={data}>
            {children}
        </SettingsContext.Provider>;
}
export default SettingsContextProvider;
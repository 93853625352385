import axios from "axios";
const tag = "@api/removeNotification";
const url = "api/removeNotification";
export const removeNotification = async ({
  uuidObserver = null,
  channelID = null
}): Promise<any> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        uuidObserver,
        channelID
      }
    };
    /*
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            if (Math.random() > 0.5) {
                resolve(null);
            } else {
                DEBUG(tag, "rejecting");
                reject(null);
            }
        }, 3000);
    }) 
    */

    let res = await axios(aopts);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default removeNotification;
import { isDesktop } from "@lib/desktop";
import { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import MenuContext from "@component/core/Context/Menu/MenuContext";
import MenuIcon from "@mui/icons-material/Menu";
export function Settings(props) {
  const desktop = isDesktop();
  const {
    setMenu
  } = useContext(MenuContext);
  function onClick() {
    setMenu({
      open: true
    });
  }
  return <IconButton {...props} size="large" edge="start" color="inherit" aria-label="menu" sx={{
    mr: 2,
    display: desktop ? "none" : "inline-flex"
  }} onClick={onClick}>
            <MenuIcon />
        </IconButton>;
}
export default Settings;
import { NavigationContext, initState } from "@component/core/Context/Navigation/NavigationContext";
import { NavigationContextState, NavigationContextStateHistoryItem, NavigationContextStateHistoryItemKey } from "@component/core/Context/Navigation/typeDefs";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import merge from "@lib/merge";
import { updateHouseholdSettingsEmitter } from "@lib/util/updateHouseholdSettings";
const tag = "@component/core/Context/Navigation/NavigationContextProvider";
export function NavigationContextProvider({
  children
}) {
  const [state, setState] = useState(initState);
  const stateRef = useRef(state);
  const setStateRef = useRef(setState);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  useEffect(() => {
    setStateRef.current = setState;
  }, [setState]);
  useEffect(() => {
    updateHouseholdSettingsEmitter.on("update", settings => {
      if (!settings.useReport && stateRef.current.history.length > 0 && stateRef.current.history[stateRef.current.history.length - 1].key === NavigationContextStateHistoryItemKey.REPORT) {
        if (stateRef.current.history.length > 1) {
          const history = ([] as Array<NavigationContextStateHistoryItem>).concat(stateRef.current.history);
          history.splice(history.length - 1, 1);
          history[history.length - 1] = {
            ...history[history.length - 1],
            active: true
          };
          const s = {
            ...stateRef.current,
            history
          };
          setStateRef.current(s);
        } else {
          const s = merge(stateRef.current, {
            history: [{
              active: true,
              key: NavigationContextStateHistoryItemKey.CALENDAR_EVENTS,
              uuid: uuidv4()
            }]
          });
          setStateRef.current(s);
        }
      }
    });
  }, []);
  const data = {
    navigation: state,
    setNavigation: (diff: Partial<NavigationContextState>) => {
      const s = merge(state, diff);
      setState(s);
    },
    pushNavigation: (item: Partial<NavigationContextStateHistoryItem>) => {
      const history = state.history.concat(([] as Array<NavigationContextStateHistoryItem>));
      history[history.length - 1] = {
        ...history[history.length - 1],
        active: false
      };
      const i: NavigationContextStateHistoryItem = {
        active: true,
        key: item.key || NavigationContextStateHistoryItemKey.INTRO,
        uuid: item.uuid || uuidv4(),
        data: item.data || undefined
      };
      history.push(i);
      const s = {
        ...state,
        history
      };
      setState(s);
    },
    backNavigation: () => {
      const history = ([] as Array<NavigationContextStateHistoryItem>).concat(state.history);
      history.splice(history.length - 1, 1);
      history[history.length - 1] = {
        ...history[history.length - 1],
        active: true
      };
      const s = {
        ...state,
        history
      };
      setState(s);
    },
    clearNavigation: () => {
      const s = {
        ...state,
        history: []
      };
      setState(s);
    }
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.navigation = data;
  }
  return <NavigationContext.Provider value={data}>
            {children}
        </NavigationContext.Provider>;
}
export default NavigationContextProvider;
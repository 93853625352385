import { AppBar, Box, CircularProgress, Dialog, IconButton, InputBase, ListItem, ListItemButton, ListItemText, Toolbar } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import { themePalette } from "@theme";
import { useContext, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from "@mui/material/Button";
import i18next from "i18next";
import React from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SelectContext from "@component/core/Context/Select/SelectContext";
import SettingsContext from "@component/core/Context/Settings/SettingsContext";
import Typography from "@mui/material/Typography";
const tag = "@component/common/Select";
const SearchIconWrapper = styled("div")(({
  theme
}) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));
const Search = styled("div")(({
  theme
}) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.1)
  },
  marginRight: 0,
  marginLeft: theme.spacing(1),
  width: "100%"
}));
const StyledInputBase = styled(InputBase)(({
  theme
}) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%'
  }
}));
function Header({
  cancel,
  onSearchChange
}) {
  return <React.Fragment>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton size="large" color="inherit" edge="start" onClick={cancel}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Hledat..." inputProps={{
            'aria-label': 'search'
          }} onChange={onSearchChange} />
                    </Search>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </React.Fragment>;
}
function Loading({
  colorPalette,
  loadingText
}) {
  return <Box sx={{
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Box sx={{
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: 90,
      height: 90
    }}>
                <CircularProgress size={60} variant="indeterminate" />
            </Box>
            <Typography variant="caption" component="div" color="text.secondary">
                {loadingText}
            </Typography>
        </Box>;
}
function LoadError({
  colorPalette,
  error,
  refresh
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 2
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.error.main,
      textAlign: "center"
    }}>
                {error}
            </Typography>
            <Button variant="outlined" color="error" sx={{
      marginTop: 1,
      padding: 1.5
    }} startIcon={<RefreshIcon />} onClick={refresh}>
                {i18next.t("common.refresh")}
            </Button>
        </Box>;
}
function Empty({
  colorPalette,
  noDataText
}) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center"
  }}>
            <Typography variant="body1" sx={{
      color: colorPalette.text.secondary,
      textAlign: "center"
    }}>
                {noDataText}
            </Typography>
        </Box>;
}
const initState = {
  items: [],
  loading: false,
  dt: Date.now(),
  error: null,
  search: ""
};
export function Select() {
  const [state, setState] = useState(initState);
  const {
    select,
    setSelect
  } = useContext(SelectContext);
  const {
    settings
  } = useContext(SettingsContext);
  const colorPalette = themePalette(settings.theme);
  function renderBasicItem(item, selected, onClick) {
    return <ListItem divider disablePadding key={item.key}>
                <ListItemButton onClick={onClick} selected={selected}>
                    <ListItemText primary={item.text} />
                </ListItemButton>
            </ListItem>;
  }
  function renderItem(index, item) {
    function onClick() {
      const onSelect = select.onSelect;
      setState({
        ...initState
      });
      setSelect({});
      if (onSelect) {
        onSelect(item);
      }
    }
    const selected = select.selected === item.key;
    if (select.renderItem) {
      return select.renderItem(item, selected, onClick);
    }
    return renderBasicItem(item, selected, onClick);
  }
  function cancel() {
    setState(initState);
    setSelect({});
  }
  function onSearchChange(ev) {
    setState({
      ...state,
      search: ev.target.value,
      dt: Date.now()
    });
  }
  async function refresh() {
    try {
      setState({
        ...initState,
        dt: Date.now(),
        search: state.search,
        loading: true
      });
      const items = await select.loadItems();
      setState({
        ...initState,
        search: state.search,
        dt: Date.now(),
        items
      });
    } catch (e) {
      LOG(tag, "refresh: error detected while gettings items, error: %O", e);
      setState({
        ...initState,
        dt: Date.now(),
        search: state.search,
        error: select.loadErrorText
      });
    }
  }
  useEffect(() => {
    refresh();
  }, [select.open]);
  const items = state.search ? state.items.filter(item => item.text.toLowerCase().indexOf(state.search.toLowerCase()) >= 0 || select.searchID && String(item.key).toLowerCase().indexOf(state.search.toLowerCase()) >= 0) : state.items;
  return <Dialog fullScreen open={select.open}>
            <div style={{
      width: "100%",
      height: "100%",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch"
    }}> 
                <Header cancel={cancel} onSearchChange={onSearchChange} />
                {/* Loading items */
      state.items.length === 0 && state.loading && <Loading colorPalette={colorPalette} loadingText={select.loadingText} />}
                {/* Cannot load items - error */
      state.items.length === 0 && !state.loading && !!state.error && <LoadError colorPalette={colorPalette} error={state.error} refresh={refresh} />}
                {/* No data */
      state.items.length === 0 && !state.loading && !state.error && <Empty colorPalette={colorPalette} noDataText={select.noDataText} />}
                {/* Item list */
      state.items.length > 0 && <Virtuoso style={{
        flex: "1 1 auto"
      }} context={{
        selected: select.selected,
        search: state.search
      }} data={items} totalCount={items.length} itemContent={renderItem} />}
            </div>
        </Dialog>;
}
export default Select;
import { ProgressContext, initState } from "@component/core/Context/Progress/ProgressContext";
import { ProgressContextState } from "@component/core/Context/Progress/typeDefs";
import { useState } from "react";
import merge from "@lib/merge";
const tag = "@component/core/Context/Progress/ProgressContextProvider";
export function ProgressContextProvider({
  children
}) {
  const [state, setState] = useState(initState);
  const data = {
    progress: state,
    setProgress: (diff: Partial<ProgressContextState>) => {
      const s = merge(initState, diff);
      setState(s);
    }
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.progress = data;
  }
  return <ProgressContext.Provider value={data}>
            {children}
        </ProgressContext.Provider>;
}
export default ProgressContextProvider;
export enum NavigationContextStateHistoryItemKey {
  INTRO = "INTRO",
  CALENDAR_EVENTS = "CALENDAR_EVENTS",
  SETTINGS = "SETTINGS",
  SET_NOTIFICATION = "SET_NOTIFICATION",
  REPORT = "REPORT",
  NOTIFICATIONS = "NOTIFICATIONS",
  HOUSEHOLD = "HOUSEHOLD",
  PICKUPS = "PICKUPS",
}
export interface NavigationContextStateHistoryItem {
  active: boolean;
  key: NavigationContextStateHistoryItemKey;
  uuid: string;
  data?: any;
}
export interface NavigationContextState {
  history: Array<NavigationContextStateHistoryItem>;
}
import { createContext } from "react";
import { NavigationContextState, NavigationContextStateHistoryItem, NavigationContextStateHistoryItemKey } from "@component/core/Context/Navigation/typeDefs";
export const initState: NavigationContextState = {
  history: []
};
export const NavigationContext = createContext({
  navigation: initState,
  setNavigation: (diff: Partial<NavigationContextState>) => {},
  pushNavigation: (item: Partial<NavigationContextStateHistoryItem>) => {},
  backNavigation: () => {},
  clearNavigation: () => {}
});
export default NavigationContext;
import EventEmitter from "eventemitter3";
const tag = "@lib/window";
export const info = {
  loaded: false,
  prompt: null,
  emitter: new EventEmitter()
};
window.addEventListener("load", async () => {
  info.loaded = true;
});
window.addEventListener("beforeinstallprompt", e => {
  e.preventDefault();
  info.prompt = e;
  if (window["showInAppInstallPromotion"]) {
    window["showInAppInstallPromotion"]();
  }
  info.emitter.emit("prompt", info.prompt);
});
export default info;
import log from "@lib/log";
import windowInfo from "@lib/window";
import serviceWorker from "@lib/serviceWorker";
import serviceWorkerInfo from "@lib/serviceWorker/info";
import i18next from "@lib/i18next";
import api from "@api";
import db from "@lib/db";
import { createRoot } from "react-dom/client";
import App from "@core/App";
//import "./calendar.scss";
import "./styles.scss";
const tag = "@entry/index";
if (process.env.NODE_ENV === 'development') {
  if (typeof localStorage.debug === "undefined") {
    log.set.all();
  }
}
const run = async () => {
  await db.open();
  if (windowInfo.loaded) {
    serviceWorker.init();
  } else {
    window.addEventListener("load", async () => {
      serviceWorker.init();
    });
  }
  const root = createRoot(document.getElementById("root"));
  root.render(<App />);
  if (process.env.NODE_ENV === 'development') {
    window["web"] = {
      LOG,
      DEBUG,
      log,
      root,
      api,
      db,
      i18next,
      serviceWorker,
      serviceWorkerInfo,
      context: {}
    };
  }
};
run();
import axios from "axios";
import moment from "moment-timezone";
const tag = "@api/getPickups";
const url = "api/getPickups";
export interface Pickup {
  id: number;
  dtPickup: string | null;
  containerInstanceID: number | null;
  text: string | null;
  wasteTypeCatalogName: string | null;
}
export const getPickups = async (householdID: number, from: string | null = null): Promise<Array<Pickup>> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        householdID,
        from: from || moment().subtract(12, "months").toISOString()
      }
    };
    /*
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            if (Math.random() > 0.5) {
                resolve(null);
            } else {
                DEBUG(tag, "rejecting");
                reject(null);
            }
        }, 3000);
    })
    */

    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data instanceof Array) {
      let ret = ([] as Array<any>).concat(res.data);
      ret = ret.map(item => ({
        ...item
      })).filter(item => typeof item.id === "number");
      ret.sort((a, b) => moment(b.dtPickup || "").diff(moment(a.dtPickup || "")));
      return (ret as Array<Pickup>);
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getPickups;
import i18next from 'i18next';
import cs from "@translations/cs.yml";
const tag = "@lib/i18next";
const conf = {
  lng: "cs",
  fallbackLng: "cs",
  ns: "translations",
  defaultNS: "translations",
  resources: {
    cs: {
      translations: cs
    }
  }
};
i18next.init(conf);
export default i18next;
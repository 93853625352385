import dexie from "@db/dexie";
import { PromiseExtended } from "dexie";
const tag = "@lib/db/util/get";

/**
 * Get one item from DB.
 * 
 * @param tableName name of DB table
 * @param filter filter function (optional)
 * @returns item object or undefined
 */
export const get = (tableName: string, filter?: (obj: any) => boolean): PromiseExtended<any> => {
  let dbInstance = dexie[tableName];
  dbInstance = filter ? dbInstance.filter(filter) : dbInstance.toCollection();
  return dbInstance.first().then((item: any) => {
    if (typeof item === "undefined") {
      LOG(tag, "cant get one item from db[\"%s\"]", tableName);
      return Promise.reject("Item is undefined");
    }
    return item;
  });
};
export default get;
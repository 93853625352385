import MenuContextProvider from "@component/core/Context/Menu/MenuContextProvider";
import NavigationContextProvider from "@component/core/Context/Navigation/NavigationContextProvider";
import SelectContextProvider from "./Select/SelectContextProvider";
import SettingsContextProvider from "./Settings/SettingsContextProvider";
import ProgressContextProvider from "./Progress/ProgressContextProvider";
import PwaContextProvider from "./Pwa/PwaContextProvider";
export function ContextProvider({
  children
}) {
  return <NavigationContextProvider>
            <MenuContextProvider>
                <SelectContextProvider>
                    <SettingsContextProvider>
                        <ProgressContextProvider>
                            <PwaContextProvider>
                                {children}
                            </PwaContextProvider>
                        </ProgressContextProvider>
                    </SettingsContextProvider>
                </SelectContextProvider>
            </MenuContextProvider>
        </NavigationContextProvider>;
}
export default ContextProvider;
import axios from "axios";
const tag = "@api/getHouseholds";
const url = "api/getHouseholds";
export interface Household {
  id: number;
  name: string | null;
  streetName: string | null;
  streetNumber: string | null;
  streetOrientationNumber: string | null;
  postalCode: string | null;
  city: string | null;
  cityPart: string | null;
}
export const getHouseholds = async (municipality: number): Promise<Array<Household>> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        municipality: [municipality]
      }
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data.households && res.data.households instanceof Array) {
      let ret = ([] as Array<any>).concat(res.data.households);
      ret = ret.map(item => ({
        ...item,
        id: typeof item.id === "number" ? item.id : null,
        name: item.name || null
      })).filter(item => typeof item.id === "number");
      ret.sort((a, b) => (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase()));
      /*
      await new Promise((resolve, reject) => {
          setTimeout(() => {
              if (Math.random() > 0.5) {
                  resolve(null);
              } else {
                  DEBUG(tag, "rejecting");
                  reject(null);
              }
          }, 5000);
      })
      */

      return (ret as Array<Household>);
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getHouseholds;
import { useContext } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from "@mui/material/IconButton";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
export function Settings(props) {
  const {
    backNavigation
  } = useContext(NavigationContext);
  function onClick() {
    backNavigation();
  }
  return <IconButton {...props} size="large" edge="start" color="inherit" aria-label="menu" sx={{
    mr: 2
  }} onClick={onClick}>
            <ArrowBackIosNewIcon />
        </IconButton>;
}
export default Settings;
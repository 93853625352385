import EventEmitter from "eventemitter3";
export interface ServiceWorkerInfo {
  ready: boolean;
  permission: NotificationPermission;
  subscription: Record<string, any> | null;
  observer: any;
  emitter: any;
}
export const info: ServiceWorkerInfo = {
  ready: false,
  permission: "Notification" in window ? window.Notification.permission : "default",
  subscription: null,
  observer: null,
  emitter: new EventEmitter()
};
export default info;
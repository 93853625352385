import EventEmitter from "eventemitter3";
export interface ServiceWorkerInfo {
  ready: boolean;
  registration: any;
  emitter: any;
}
export const info: ServiceWorkerInfo = {
  ready: false,
  registration: null,
  emitter: new EventEmitter()
};
export default info;
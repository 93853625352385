import { Dexie } from "dexie";
import Settings from "@db/table/Settings/typeDefs";
import { Table } from "@db/table/typeDefs";
const tag = "@lib/db/dexie";
const DB_VERSION = 1;
const DB_NAME = "mojepopelnice-pwa";
export class DB extends Dexie {
  public Settings: Dexie.Table<Settings, number>;
  public constructor() {
    super(DB_NAME);

    // Initialize DB
    this.version(DB_VERSION).stores({
      [Table.SETTINGS]: "++id"
    });

    // Initialize tables
    this.Settings = this.table(Table.SETTINGS);
    this.on("ready", async () => {
      if (process.env.NODE_ENV === 'development') {}
    });
  }
}

// Dexie DB instance
export const dexie = new DB();
export default dexie;
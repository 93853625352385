import axios from "axios";
const tag = "@api/createContainerReport";
const url = "api/createContainerReport";
export interface ContainerReportType {
  id: number;
  name: string | null;
}
export const getContainerReportTypes = async (municipalityID, description, typeID, householdID = null, containerInstanceID = null): Promise<void> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        municipalityID,
        description,
        typeID,
        householdID,
        containerInstanceID
      }
    };
    /*
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            if (Math.random() > 0.5) {
                resolve(null);
            } else {
                DEBUG(tag, "rejecting");
                reject(null);
            }
        }, 3000);
    })
    */

    let res = await axios(aopts);
    if (res.status === 200) {
      return;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getContainerReportTypes;
import axios from "axios";
const tag = "@api/getHouseholdSettings";
const url = "api/getHouseholdSettings";
export interface HouseholdSettings {
  useReport: boolean;
  showEventTime: boolean;
}
export const getHouseholdSettings = async (municipality: number): Promise<HouseholdSettings> => {
  try {
    const aopts = {
      method: "post",
      url,
      headers: {
        "accept": "application/json"
      },
      data: {
        municipality: municipality
      }
    };
    /*
        // random success / error with delay (for simulations)
        await new Promise((resolve, reject) => {
            setTimeout(() => {
                if (Math.random() > 0.5) {
                    resolve(null);
                } else {
                    DEBUG(tag, "rejecting");
                    reject(null);
                }
            }, 5000);
        })
    */

    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data) {
      const householdSettings: HouseholdSettings = {
        useReport: !!res.data.useReport,
        showEventTime: !!res.data.showEventTime
      };
      return householdSettings;
    } else {
      LOG(tag, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(tag, "error detected %O", e);
    throw e;
  }
};
export default getHouseholdSettings;